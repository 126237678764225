import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import './Home.css'; // Import the stylesheet


const HomePage = () => {

  useEffect(() => {
    // Temporary simplification
    return () => {};  // Empty cleanup function
  }, []);

  return (
    <div className="home-container">
      <header className="home-header">
        <nav className="navigation">
          <ul>
            <li><Link to="/features">FEATURES</Link></li>
            <li><Link to="/docs">DOCUMENTATION</Link></li>
            <li><Link to="/pricing">PRICING</Link></li>
            <li><Link to="/about">ABOUT</Link></li>
            <li><Link to="/register">SIGN UP</Link></li>
            <li><Link to="/login">LOG IN</Link></li>
            <li><Link to="/console">CONSOLE</Link></li>
          </ul>
        </nav>
      </header>
      <main className="main-content">
        {/* <div className="svg-wrapper">
          <img src={'/img/home_cover.svg'} />
        </div> */}
        <h1 className="main-title">BitKiosk</h1>
        <p className="main-description">
          Do More with Bitcoin
        </p>
        {/* <button className="main-button">GET STARTED</button> */}
        
      </main>
    </div>
  );
};

export default HomePage;
