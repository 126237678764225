import React from 'react';

import './Text.css';

const Text = ({top,bottom,align,style,weight,children,t,onClick, className}) => {
   

    return (
        <div className={`text Text${t} ${className}`} onClick={onClick} style={{display:`block`,width:`100%`,marginBlockStart:`${top}`,marginBlockEnd:`${bottom}`,textAlign:`${align}`,fontWeight:`${weight}`,fontStyle:`${style}`}} >
            {children}
        </div>
    );
}

export default Text;