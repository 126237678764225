import React, {useState,useCallback,useEffect} from 'react';
import axios, {isCancel, AxiosError} from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

function ProtectiveConsoleWrapper({ children }) {
    const navigate = useNavigate();
    const checkingLocalStorageAttempt = useSelector((store) => store.users.checkingLocalStorageAttempt);
    const userToken = useSelector((store) => store.users.userToken);

    useEffect(() => {
        // Ensure checkingLocalStorageAttempt is finished before navigating
        if (!checkingLocalStorageAttempt) {
            // Maybe show a loading indicator or handle this state differently
            console.log("Checking local storage...");
        } else if (!userToken) {
            console.log("No user token found, redirecting to home...");
            navigate('/'); // Redirect to home if no user token found
        }
    }, [checkingLocalStorageAttempt, userToken, navigate]);

    if (!checkingLocalStorageAttempt || !userToken) {
        // Return null, a loader, or a placeholder to prevent children from rendering
        return <div className="empty-wrapper"></div>;
    }

    // If everything is okay, render children
    return children;
}

export default ProtectiveConsoleWrapper;
