import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, {isCancel, AxiosError} from 'axios';

import { internal_api_href } from "../store";

import { setBillingDetails,setBasicSettings } from "./userSlice";

export const refreshBasicSettings = createAsyncThunk(
    'settings/refreshBasicSettings',
    async ({userToken}, { dispatch, rejectWithValue }) => {
      try {
        // Perform the registration logic here using the apiKeyData
        const postData = {
          token:userToken,
          };
  
          const config = {
              method: 'post',
              url: `${internal_api_href}refresh-basic-settings`,
              headers: { 
                'Content-Type': 'application/json',
              },
              data: postData
            };
  
            const result = await axios(config);
            dispatch(setBasicSettings({result}));
            return result;
        // Return the registered API key or relevant data if successful
        
      } catch (error) {
        // Handle any errors that occur during the registration process
  
        // If necessary, you can reject the promise with a specific error value using rejectWithValue()
        return rejectWithValue(error);
      }
    }
  );

  export const refreshBillingSettings = createAsyncThunk(
    'settings/refreshBillingSettings',
    async ({userToken}, { dispatch,rejectWithValue }) => {
      try {
        // Perform the registration logic here using the apiKeyData
        const postData = {
          token:userToken,
          };
  
          const config = {
              method: 'post',
              url: `${internal_api_href}refresh-billing-settings`,
              headers: { 
                'Content-Type': 'application/json',
              },
              data: postData
            };
  
            const result = await axios(config);
            dispatch(setBillingDetails({result}));
            return result;
        // Return the registered API key or relevant data if successful
        
      } catch (error) {
        // Handle any errors that occur during the registration process
  
        // If necessary, you can reject the promise with a specific error value using rejectWithValue()
        return rejectWithValue(error);
      }
    }
  );

  export const initializePaymentLink_InitialSubscription = createAsyncThunk(
    'settings/initializePaymentLink_InitialSubscription',
    async ({userToken}, { rejectWithValue }) => {
      const startTime = Date.now();
      try {
        //First refresh userToken with 60 minutes valid
        const refreshTokenData = {
          token:userToken
        }
        const refreshConfig = {
          method:'post',
          url: `${internal_api_href}refresh-token`,
          headers: { 
            'Content-Type': 'application/json',
          },
          data: refreshTokenData
        }

        const newToken = await axios(refreshConfig);
        console.log("newToken: ",newToken);
        const {data} = newToken;
        const {token} = data;
        localStorage.setItem(
          'BitKioskUser',
          JSON.stringify({
            token:token
          })
        );

        // Perform the registration logic here using the apiKeyData
        const postData = {
          token:userToken,
          };
  
          const config = {
              method: 'post',
              url: `${internal_api_href}Initialize-Payment-Link_Initial-Subscription`,
              headers: { 
                'Content-Type': 'application/json',
              },
              data: postData
            };
  
            const result = await axios(config);

            const endTime = Date.now();
            const timeDiff = endTime - startTime;
            if(timeDiff >= 2000){
              return result;
            }else{
              const waitTime = 2000 - timeDiff;
              await new Promise((res,rej)=>{
                setTimeout(()=>{
                  res();
                },waitTime);
              });
              return result;
            }
            
        // Return the registered API key or relevant data if successful
        
      } catch (error) {
        // Handle any errors that occur during the registration process
  
        // If necessary, you can reject the promise with a specific error value using rejectWithValue()
        const endTime = Date.now();
        const timeDiff = endTime - startTime;
        if(timeDiff >= 2000){
          return rejectWithValue(error);
        }else{
          const waitTime = 2000 - timeDiff;
          await new Promise((res,rej)=>{
            setTimeout(()=>{
              res();
            },waitTime);
          });
          return rejectWithValue(error);
        }
        
      }
    }
  );

  export const addPaymentMethod1 = createAsyncThunk(
    'settings/addPaymentMethod1',
    async ({userToken}, { dispatch,rejectWithValue }) => {
      try {
        // Perform the registration logic here using the apiKeyData
        const postData = {
          token:userToken,
          };
  
          const config = {
              method: 'post',
              url: `${internal_api_href}add-payment-method-setupIntent`,
              headers: { 
                'Content-Type': 'application/json',
              },
              data: postData
            };
            
            const result = await axios(config);
            console.log(result);
            return result;
        // Return the registered API key or relevant data if successful
        
      } catch (error) {
        // Handle any errors that occur during the registration process

        // If necessary, you can reject the promise with a specific error value using rejectWithValue()
          return rejectWithValue(error);
      }
    }
  );

  export const addPaymentMethod2 = createAsyncThunk(
    'settings/addPaymentMethod2',
    async ({userToken,setupIntent}, { dispatch,rejectWithValue }) => {
      try {
        // Perform the registration logic here using the apiKeyData
        const postData = {
          token:userToken,
          setupIntent:setupIntent
          };
  
          const config = {
              method: 'post',
              url: `${internal_api_href}add-payment-method-finalizeIntent`,
              headers: { 
                'Content-Type': 'application/json',
              },
              data: postData
            };
            
            const result = await axios(config);
            dispatch(setBillingDetails({result}));
            return result;
        // Return the registered API key or relevant data if successful
        
      } catch (error) {
        // Handle any errors that occur during the registration process
  
        // If necessary, you can reject the promise with a specific error value using rejectWithValue()
          return rejectWithValue(error);
        
        
      }
    }
  );

  export const removePaymentMethod = createAsyncThunk(
    'settings/removePaymentMethod',
    async ({userToken,paymentMethodID}, { dispatch,rejectWithValue }) => {
      try {
        // Perform the registration logic here using the apiKeyData
        const postData = {
          token:userToken,
          pid:paymentMethodID
          };
  
          const config = {
              method: 'post',
              url: `${internal_api_href}remove-payment-method`,
              headers: { 
                'Content-Type': 'application/json',
              },
              data: postData
            };
            
            const result = await axios(config);
            dispatch(setBillingDetails({result}));
            return result;
        // Return the registered API key or relevant data if successful
        
      } catch (error) {
        // Handle any errors that occur during the registration process
  
        // If necessary, you can reject the promise with a specific error value using rejectWithValue()
          return rejectWithValue(error);
        
      }
    }
  );


  export const makePaymentMethodDefault = createAsyncThunk(
    'settings/makePaymentMethodDefault',
    async ({userToken,paymentMethodID}, { dispatch,rejectWithValue }) => {
      try {
        // Perform the registration logic here using the apiKeyData
        const postData = {
          token:userToken,
          pid:paymentMethodID
          };
  
          const config = {
              method: 'post',
              url: `${internal_api_href}make-payment-method-default`,
              headers: { 
                'Content-Type': 'application/json',
              },
              data: postData
            };
            
            const result = await axios(config);
            console.log(result);
            dispatch(setBillingDetails({result}));
            return result;
        // Return the registered API key or relevant data if successful
        
      } catch (error) {
        // Handle any errors that occur during the registration process
  
        // If necessary, you can reject the promise with a specific error value using rejectWithValue()
          return rejectWithValue(error);
        
      }
    }
  );





export const settingsSlice = createSlice({
    name:'settings',
    initialState:{
        basicSettingsLoading:false,
        basicSettingsStatus:{
            success:false,
            attempt:false
        },
        orgDetails:{
            orgID:'',
            orgName:''
        },
        profileDetails:{
            name:'',
            number:'',
            email:''
        },
        billingSettingsLoading:false,
        billingSettingsStatus:{
          success:false,
          attempt:false
        },
        billingDetails:{

        },
        initializePaymentLinkLoading:false,
        initializePaymentLinkStatus:{
          success:false,
          attempt:false,
          error:false,
          errorCode:0,
          paymentLink:''
        },

        addPaymentMethodLoading:false,
        addPaymentMethod1Status:{
          success:false,
          attempt:false,
          error:0,
          secret:''
        },
        addPaymentMethod2Status:{
          success:false,
          attempt:false,
          error:0,
        },

        removePaymentMethodLoading:false,
        removePaymentMethodStatus:{
          success:false,
          attempt:false,
          error:0,
        },


        makePaymentMethodDefaultLoading:false,
        makePaymentMethodDefaultStatus:{
          success:false,
          attempt:false,
          error:0,
        },


        navigateToBilling:false,
    },
    reducers:{
      setNavigateToBilling: (state, action) => {
        state.navigateToBilling = true;
      },
      removeNavigateToBilling: (state, action) => {
        state.navigateToBilling = false;
      },
      refreshAddPaymentMethodStatus: (state,action) => {
        state.addPaymentMethod1Status={
          success:false,
          attempt:false,
          error:0,
          secret:''
        };
        state.addPaymentMethod2Status={
          success:false,
          attempt:false,
          error:0
        };
      },
      refreshRemovePaymentMethodStatus: (state,action) => {
        state.removePaymentMethodStatus = {
          success:false,
          attempt:false,
          error:0,
        }
      },
      refreshMakePaymentMethodDefaultStatus: (state,action) => {
        state.makePaymentMethodDefaultStatus = {
          success:false,
          attempt:false,
          error:0,
        }
      },
      refreshInitializeSubscriptionLink: (state) => {
        state.initializePaymentLinkStatus={
          success:false,
          attempt:false,
          error:false,
          errorCode:0,
          paymentLink:''
        }
      }
    },
    extraReducers:(builder) => {
        builder
          .addCase(refreshBasicSettings.pending, (state, action) => {
            // Handle pending action, if needed
            state.basicSettingsLoading = true;
          })
          .addCase(refreshBasicSettings.fulfilled, (state, action) => {
            // Handle fulfilled action
            // You can access the response data using action.payload
            state.basicSettingsLoading = false;
            const {data} = action.payload;
            state.orgDetails = data.orgDetails;
            state.profileDetails = data.profileDetails;
            state.basicSettingsStatus = {
                success:true,
                attempt:true
            }
          })
          .addCase(refreshBasicSettings.rejected, (state, action) => {
            // Handle rejected action
            // You can access the error using action.error
            state.basicSettingsLoading = false;
            console.log(action.payload);
          })



          .addCase(refreshBillingSettings.pending, (state, action) => {
            // Handle pending action, if needed
            state.billingSettingsLoading = true;
          })
          .addCase(refreshBillingSettings.fulfilled, (state, action) => {
            // Handle fulfilled action
            // You can access the response data using action.payload
            state.billingSettingsLoading = false;
            const {data} = action.payload;
            state.billingSettingsStatus = {
              success:true,
              attempt:true
            }
            state.billingDetails = data.billingDetails;
          })
          .addCase(refreshBillingSettings.rejected, (state, action) => {
            // Handle rejected action
            // You can access the error using action.error
            state.billingSettingsLoading = false;
            console.log(action.payload);
            state.billingSettingsStatus = {
              success:false,
              attempt:true
            }
          })


          .addCase(initializePaymentLink_InitialSubscription.pending, (state, action) => {
            // Handle pending action, if needed
            state.initializePaymentLinkLoading = true;
          })
          .addCase(initializePaymentLink_InitialSubscription.fulfilled, (state, action) => {
            // Handle fulfilled action
            // You can access the response data using action.payload
            state.initializePaymentLinkLoading = false;
            const {data} = action.payload;
            console.log(data);
            const {plinkID,url} = data; 
            state.initializePaymentLinkStatus = {
              success:true,
              attempt:true,
              error:false,
              errorCode:0,
              paymentLink:url
            }
          })
          .addCase(initializePaymentLink_InitialSubscription.rejected, (state, action) => {
            // Handle rejected action
            // You can access the error using action.error
            state.initializePaymentLinkLoading = false;
            const {response} = action.payload;
            console.log(response);
            const {data,status} = response;
            state.initializePaymentLinkStatus = {
              success:false,
              attempt:true,
              error:status,
            }
          })


          .addCase(addPaymentMethod1.pending, (state, action) => {
            // Handle pending action, if needed
            state.addPaymentMethodLoading = true;
          })
          .addCase(addPaymentMethod1.fulfilled, (state, action) => {
            // Handle fulfilled action
            // You can access the response data using action.payload
            const {data} = action.payload;
            const {secret} = data;
            state.addPaymentMethod1Status = {
              success:true,
              attempt:true,
              error:0,
              secret:secret
            }
          })
          .addCase(addPaymentMethod1.rejected, (state, action) => {
            // Handle rejected action
            // You can access the error using action.error
            state.addPaymentMethodLoading = false;
            console.log(action.payload);
            const { response } = action.payload;
            const {data,status} = response;
            state.addPaymentMethod1Status = {
              success:false,
              attempt:true,
              error:status
            }
          })



          .addCase(addPaymentMethod2.pending, (state, action) => {
            // Handle pending action, if needed
            state.addPaymentMethodLoading = true;
          })
          .addCase(addPaymentMethod2.fulfilled, (state, action) => {
            // Handle fulfilled action
            // You can access the response data using action.payload
            state.addPaymentMethodLoading = false;
            const {data} = action.payload;
            state.addPaymentMethod2Status = {
              success:true,
              attempt:true,
              error:0,
            }
          })
          .addCase(addPaymentMethod2.rejected, (state, action) => {
            // Handle rejected action
            // You can access the error using action.error
            state.addPaymentMethodLoading = false;
            const { response } = action.payload;
            const {data,status} = response;
            state.addPaymentMethod2Status = {
              success:false,
              attempt:true,
              error:status
            }
          })


          .addCase(removePaymentMethod.pending, (state, action) => {
            // Handle pending action, if needed
            state.removePaymentMethodLoading = true;
          })
          .addCase(removePaymentMethod.fulfilled, (state, action) => {
            // Handle fulfilled action
            // You can access the response data using action.payload
            state.removePaymentMethodLoading = false;
            const {data} = action.payload;
            console.log(data);
            state.removePaymentMethodStatus = {
              success:true,
              attempt:true,
              error:0,
            }
          })
          .addCase(removePaymentMethod.rejected, (state, action) => {
            // Handle rejected action
            // You can access the error using action.error
            state.removePaymentMethodLoading = false;
            console.log(action.payload);
            const { response } = action.payload;
            const {data,status} = response;
            state.removePaymentMethodStatus = {
              success:false,
              attempt:true,
              error:status
            }
          })



          .addCase(makePaymentMethodDefault.pending, (state, action) => {
            // Handle pending action, if needed
            state.makePaymentMethodDefaultLoading = true;
          })
          .addCase(makePaymentMethodDefault.fulfilled, (state, action) => {
            // Handle fulfilled action
            // You can access the response data using action.payload
            state.makePaymentMethodDefaultLoading = false;
            const {data} = action.payload;
            console.log(data);
            state.makePaymentMethodDefaultStatus = {
              success:true,
              attempt:true,
              error:0,
            }
          })
          .addCase(makePaymentMethodDefault.rejected, (state, action) => {
            // Handle rejected action
            // You can access the error using action.error
            state.makePaymentMethodDefaultLoading = false;
            console.log(action.payload);
            const { response } = action.payload;
            const {data,status} = response;
            state.makePaymentMethodDefaultStatus = {
              success:false,
              attempt:true,
              error:status
            }
          })


    }
});


export default settingsSlice.reducer;
export const {
  setNavigateToBilling,
  removeNavigateToBilling,
  refreshAddPaymentMethodStatus,
  refreshRemovePaymentMethodStatus,
  refreshMakePaymentMethodDefaultStatus,
  refreshInitializeSubscriptionLink
} = settingsSlice.actions;