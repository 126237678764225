import React, { useReducer, useEffect } from 'react';

import Text from '../UIElements/Text';
import Title from '../UIElements/Title';

import { validate } from '../../util/validators';
import './Input.css';

const inputReducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE':
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators)
      };
    case 'TOUCH': {
      return {
        ...state,
        isTouched: true
      }
    }
    default:
      return state;
  }
};

const Input = props => {
  const [inputState, dispatch] = useReducer(inputReducer, {
    value: props.initialValue || '',
    isTouched: false,
    isValid: props.initialValid || false
  });

  const { id, onInput, wasTouched, mismatch, mismatchText, readonly, t, ref } = props;
  const { value, isValid } = inputState;

  useEffect(() => {
    onInput(id, value, isValid)
  }, [id, value, isValid, onInput]);

  const changeHandler = event => {
    dispatch({
      type: 'CHANGE',
      val: event.target.value,
      validators: props.validators
    });
  };

  const touchHandler = () => {
    if(wasTouched){wasTouched();};
    dispatch({
      type: 'TOUCH'
    });
  };

  useEffect(() => {
    if(props.initialValue){
      dispatch({
        type: 'CHANGE',
        val: props.initialValue,
        validators: props.validators
      });
    }
  }, [props.initialValue]);

  const element =
    props.element === 'input' ? (
      <input
        id={props.id}
        type={props.type}
        placeholder={props.placeholder}
        onChange={changeHandler}
        onBlur={touchHandler}
        value={inputState.value}
        readOnly={readonly}
        ref={ref}
      />
    ) : (
      <textarea
        id={props.id}
        rows={props.rows || 3}
        onChange={changeHandler}
        onBlur={touchHandler}
        value={inputState.value}
        readOnly={readonly}
        ref={ref}
      />
    );

  return (
    <div
      className={`form-control ${!inputState.isValid && inputState.isTouched &&
        'form-control--invalid'} ${mismatch && 'form-control--invalid'} ${t}` 
      }
    >
      <label htmlFor={props.id}>{props.label}</label>
      {element}
      {!inputState.isValid && inputState.isTouched ? <p>{props.errorText}</p> : <p></p>}
      {mismatch && <p>{mismatchText}</p>}
    </div>
  );
};

export default Input;
