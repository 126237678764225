import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, {isCancel, AxiosError} from 'axios';

import { internal_api_href } from "../store";
//Fix for Errors
export const fetchRegistrationKey = createAsyncThunk(
    'user/fetchRegistrationKey',
    async(_, {rejectWithValue}) => {
        try {
            const response = await axios.get(`${internal_api_href}user-registration-token`);
            const {data,status} = response;
            if(status === 200){
                return(data.jwt);
            }else{
                if(status === '404'){
                    return rejectWithValue('Server Offline. Try Back Later');
                }else{
                    return rejectWithValue('Something went wrong. Refresh, try again');
                }
            }
          } catch (err) {
            return rejectWithValue('Something went wrong. Refresh, try again');
          }
    }
);

export const sendRegistrationDetails = createAsyncThunk(
    'user/sendRegistrationDetails',
    async(details,{rejectWithValue}) => {
        try {
            const {email,password,token} = details;
                  const postData = {
                      email:email,
                      password:password,
                      token:token,
                  };
            
                    const config = {
                        method: 'post',
                        url: `${internal_api_href}user-registration`,
                        headers: { 
                        'Content-Type': 'application/json',
                        },
                        data: postData
                    };

                   const response = await axios(config);
                   const {user,token:userToken} = response.data;
                    return({user,userToken});
                } catch (error) {
                    console.log(error);
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        return rejectWithValue({status:error.response.status,message:error.response.data});
                      } else if (error.request) {
                        // The request was made but no response was received
                        
                        return rejectWithValue({status:0,message:"Server unavailable. Please try again"});
                      } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                        return rejectWithValue({status:1,message:"Something went wrong. Please try again"});
                      }
                }
    }
)

//Check Local Storage
//Check userToken Against Server - Refresh User Details - Refresh userToken
export const checkUserInLocalStorage = createAsyncThunk(
  'user/checkUserInLocalStorage',
  async (_, thunkAPI) => {
    const user = localStorage.getItem('BitKioskUser'); // Replace 'user' with the key you use in localStorage
    if (user) {
      return JSON.parse(user); // Parse the user data and return it
    } else {
      console.log("fail");
      return thunkAPI.rejectWithValue('No user found in localStorage');
    }
  }
);



export const postCheckoutBilling = createAsyncThunk(
  'user/postCheckoutBilling',
  async(details, {rejectWithValue}) => {
      try {
        const {token,checkout_session} = details;
        const postData = {
          checkout_session:checkout_session,
          token:token,
          };
        const config = {
            method: 'post',
            url: `${internal_api_href}post-checkout-billing`,
            headers: { 
            'Content-Type': 'application/json',
            },
            data: postData
        };
          const response = await axios(config);
          const {data,status} = response;
              return data;
        } catch (err) {
          return rejectWithValue(err);
        }
  }
);



export const refreshUserDetails = createAsyncThunk(
  'user/refreshUserDetails',
  async({userToken}, {dispatch, rejectWithValue}) => {
      try {
        const postData = {
          token:userToken,
          };
        const config = {
            method: 'post',
            url: `${internal_api_href}refresh-user-details`,
            headers: { 
            'Content-Type': 'application/json',
            },
            data: postData
        };
          const response = await axios(config);
          const {data,status} = response;
          dispatch(setUserDetails(data));
            return data;
        } catch (err) {
          return rejectWithValue('Something went wrong. Refresh, try again');
        }
  }
);





export const userSlice = createSlice({
    name:'user',
    initialState:{
        registrationKey:'',
        registrationKeyLoading:true,
        registrationKeyError:false,
        registrationKeyErrorText:'',
        userRegistrationLoading:false,
        userRegistrationError:false,
        userRegistrationErrorText:'',
        userRegisterSuccess:false,
        userDetails:{},
        userDetailsSet:false,
        userToken:'',

        checkingLocalStorageLoading:false,
        checkingLocalStorageAttempt:false,

        postCheckoutBillingLoading:false,
        postCheckoutBillingStatus:{
          success:false,
          attempt:false,
          error:0,
        },

        refreshUserDetailsLoading:false,
        refreshUserDetailsStatus:{
          success:false,
          attempt:false,
          error:0
        }
    },
    reducers:{
      setUserDetails: (state, action) => {
        console.log(action);
        const {user,token} = action.payload;
        state.userDetails = user;
        state.userToken = token;
        state.userDetailsSet = true;
        localStorage.setItem(
          'BitKioskUser',
          JSON.stringify({
            token:token
          })
        );
      },
      setBillingDetails: (state, action) => {
        const {payload} = action;
        const {data} = payload.result;
        const {billingDetails} = data;
        state.userDetails.billingDetails = billingDetails;
      },
      setBasicSettings: (state, action) => {
        const {payload} = action;
        const {data} = payload.result;
        const {orgDetails,profileDetails} = data;
        const {orgID,orgName} = orgDetails;
        const {name,number,email} = profileDetails;
        state.userDetails.name = name;
        state.userDetails.orgID = orgID;
        state.userDetails.orgName = orgName;
        state.userDetails.userName = email;
      },
      refreshUserRegistrationAttempt: (state) => {
        state.userRegistrationError = false;
        state.userRegistrationErrorText = false;
        state.userRegisterSuccess = false;
      },
      logoutSequence: (state) => {
        state.userToken = ''
      },
    },
    extraReducers:(builder) => {
        builder
          .addCase(fetchRegistrationKey.pending, (state, action) => {
            // Handle pending action, if needed
            state.registrationKeyLoading = true;
          })
          .addCase(fetchRegistrationKey.fulfilled, (state, action) => {
            // Handle fulfilled action
            // You can access the response data using action.payload
            console.log(action.payload);
            state.registrationKeyLoading = false;
            state.registrationKey = action.payload;
          })
          .addCase(fetchRegistrationKey.rejected, (state, action) => {
            // Handle rejected action
            // You can access the error using action.error
            state.registrationKeyLoading = false;
            state.registrationKeyError = action.payload;
          })



          .addCase(sendRegistrationDetails.pending, (state, action) => {
            // Handle pending action, if needed
            state.userRegistrationLoading = true;
          })
          .addCase(sendRegistrationDetails.fulfilled, (state, action) => {
            // Handle fulfilled action
            // You can access the response data using action.payload
            state.userRegistrationLoading = false;
            state.userRegisterSuccess = true;
            const { user,userToken } = action.payload;
            localStorage.setItem(
              'BitKioskUser',
              JSON.stringify({
                token:userToken
              })
            );
            state.userToken = userToken;
            state.userDetails = user;
          })
          .addCase(sendRegistrationDetails.rejected, (state, action) => {
            // Handle rejected action
            // You can access the error using action.error
            state.userRegistrationLoading = false;
            state.userRegisterSuccess = false;
            state.userRegistrationError = true;
            state.userRegistrationErrorText = action.payload.message;
          })



          .addCase(checkUserInLocalStorage.pending, (state, action) => {
            // Handle pending action, if needed
            state.checkingLocalStorageLoading = true;
          })
          .addCase(checkUserInLocalStorage.fulfilled, (state, action) => {
            // Handle fulfilled action
            // You can access the response data using action.payload
            state.checkingLocalStorageLoading = false;
            console.log("localStorage:",action.payload);
            const {token} = action.payload;
            state.userToken = token;
            state.checkingLocalStorageAttempt = true;
          })
          .addCase(checkUserInLocalStorage.rejected, (state, action) => {
            // Handle rejected action
            // You can access the error using action.error
            console.log("WOOP");
            state.checkingLocalStorageLoading = false;
            state.checkingLocalStorageAttempt = true;
          })


          .addCase(postCheckoutBilling.pending, (state, action) => {
            // Handle pending action, if needed
            state.postCheckoutBillingLoading = true;
          })
          .addCase(postCheckoutBilling.fulfilled, (state, action) => {
            // Handle fulfilled action
            // You can access the response data using action.payload
            state.postCheckoutBillingLoading = false;
            console.log("Billing:",action.payload);
            state.postCheckoutBillingStatus = {
              success:true,
              attempt:true,
              error:0
            }
          })
          .addCase(postCheckoutBilling.rejected, (state, action) => {
            // Handle rejected action
            // You can access the error using action.error
            console.log("Billing Error");
            const {response} = action.payload;
            console.log(response);
            const {data,status} = response;
            state.postCheckoutBillingLoading = false;
            state.postCheckoutBillingStatus = {
              success:false,
              attempt:true,
              error:status
            }
          })


          .addCase(refreshUserDetails.pending, (state, action) => {
            // Handle pending action, if needed
            state.refreshUserDetailsLoading = true;
          })
          .addCase(refreshUserDetails.fulfilled, (state, action) => {
            // Handle fulfilled action
            // You can access the response data using action.payload
            state.refreshUserDetailsLoading = false;
            console.log("Refresh Details:",action.payload);
            state.refreshUserDetailsStatus = {
              success:true,
              attempt:true,
              error:0
            }
          })
          .addCase(refreshUserDetails.rejected, (state, action) => {
            // Handle rejected action
            // You can access the error using action.error
            console.log("Refresh Error");
            state.refreshUserDetailsLoading = false;
            state.refreshUserDetailsStatus = {
              success:false,
              attempt:true,
              error:0
            }
          })



    }
});


export default userSlice.reducer;
export const {setUserDetails,setBillingDetails,setBasicSettings,refreshUserRegistrationAttempt} = userSlice.actions;