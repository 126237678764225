import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, {isCancel, AxiosError} from 'axios';

import { internal_api_href } from "../store";


export const refreshWallet = createAsyncThunk(
    'wallet/refreshWallet',
    async ({userToken}, { rejectWithValue }) => {
      try {
        // Perform the registration logic here using the apiKeyData
        const postData = {
          token:userToken,
          };
  
          const config = {
              method: 'post',
              url: `${internal_api_href}refresh-wallet`,
              headers: { 
                'Content-Type': 'application/json',
              },
              data: postData
            };
  
            const result = await axios(config);
            return result;
        // Return the registered API key or relevant data if successful
        
      } catch (error) {
        // Handle any errors that occur during the registration process
        console.log(error);
        // If necessary, you can reject the promise with a specific error value using rejectWithValue()
        return rejectWithValue(error);
      }
    }
  );


  export const walletSlice = createSlice({
    name:'wallet',
    initialState:{
        refreshWalletLoading:false,
        refreshWalletStatus:{
            success:false,
            attempt:false
        },
        walletDetails:{
            walletLoaded:false,
            walletInit:false,
            walletDetailsBook:[],
            tier:0,
            pricePer10000:0,
            blockchain_height:{height:0,time:Date.now()},
            fees:{fees:{}},
            wallet_stats:{
              w_total_uses:0,
              w_usd_volume:0,
              w_btc_volume:0
            },
            historicalWalletDetails:{},
        }
    },
    reducers:{

    },
    extraReducers:(builder) => {
        builder
          .addCase(refreshWallet.pending, (state, action) => {
            // Handle pending action, if needed
            state.refreshWalletLoading = true;
          })
          .addCase(refreshWallet.fulfilled, (state, action) => {
            // Handle fulfilled action
            // You can access the response data using action.payload
            state.refreshWalletLoading = false;
            const {data} = action.payload;
            const {walletLoaded, walletInit,walletDetailBook,tier,pricePer10000_BTC,blockchain_height,fees,wallet_stats,historicalWalletDetails} = data;
            state.walletDetails = {
                walletLoaded:walletLoaded,
                walletInit:walletInit,
                walletDetailsBook:walletDetailBook,
                tier:tier,
                pricePer10000:pricePer10000_BTC,
                blockchain_height:blockchain_height,
                fees:fees,
                wallet_stats:wallet_stats,
                historicalWalletDetails:historicalWalletDetails,
            }
            state.refreshWalletStatus = {
                success:true,
                attempt:true
            }
          })
          .addCase(refreshWallet.rejected, (state, action) => {
            // Handle rejected action
            // You can access the error using action.error
            state.refreshWalletLoading = false;
            console.log(action);
          })
    }
});


export default walletSlice.reducer;
