import React, {useEffect} from 'react';

const PrivacyPolicy = () => {

  useEffect(() => {
    // Temporary simplification
    return () => {};  // Empty cleanup function
  }, []);

  return (
    <div className="privacy-container">
            Privacy Policy
    </div>
  );
};

export default PrivacyPolicy;
