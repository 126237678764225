import React, {useState,useCallback,useEffect} from 'react';
import axios, {isCancel, AxiosError} from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import API_Keys from './Modules/API_Keys';
import Settings from './Modules/Settings';
import Documentation from './Modules/Documentation';
import Wallet from './Modules/Wallet';

import { removeNavigateToBilling } from '../../shared/store/slices/settingsSlice';
import { refreshUserDetails } from '../../shared/store/slices/userSlice';

import './Console.css'

function Console(){
    const dispatch = useDispatch();
    const navigateToBilling = useSelector((store) => store.settings.navigateToBilling);
    const userDetailsSet = useSelector((store) => store.users.userDetailsSet);
    const userToken = useSelector((store) => store.users.userToken);
    const [moduleDisplayed, setModuleDisplayed] = useState(navigateToBilling ? "settings" : "wallet");
    const [settingDisplayed,setSettingDisplayed] = useState(navigateToBilling ? "billing" : "organization");

    if(navigateToBilling){
        setTimeout(()=>{
            dispatch(removeNavigateToBilling());
        },100);
    }

    useEffect(()=>{
        if(navigateToBilling){
            setModuleDisplayed("settings");
            setSettingDisplayed("billing");
        }
    },[navigateToBilling])

    let activeModule;

    if (moduleDisplayed === 'wallet') {
        activeModule = <Wallet />;
    } else if (moduleDisplayed === 'settings') {
        activeModule = <Settings 
        settingDisplayed={settingDisplayed}
            />;
    } else if (moduleDisplayed === 'api_keys') {
        activeModule = <API_Keys />;
    } else if (moduleDisplayed === 'documentation') {
        activeModule = <Documentation />;
    }

    function clickToSetActiveWallet(){
        setModuleDisplayed('wallet');
        setSettingDisplayed("organization");
    }
    function clickToSetActiveSettings(){
        setModuleDisplayed('settings');
        setSettingDisplayed("organization");
    }
    function clickToSetActiveAPIKeys(){
        setModuleDisplayed('api_keys');
        setSettingDisplayed("organization");
    }
    function clickToSetActiveDocs(){
        setModuleDisplayed('documentation');
        setSettingDisplayed("organization");
    }




    function clickSettingsOrganization(){
        setSettingDisplayed("organization");
    }
    function clickSettingsTeam(){
        setSettingDisplayed("team");
    }
    function clickSettingsBilling(){
        setSettingDisplayed("billing");
    }
    function clickSettingsProfile(){
        setSettingDisplayed("profile");
    }



    useEffect(()=>{
        if(!userDetailsSet && userToken){
            dispatch(refreshUserDetails({userToken}));
        }
    },[userDetailsSet,userToken]);



return(
    <div className="console-wrap">
        <div className="console-header">
                <div className="logo-header">
                    <div className="Title1">
                        BitKiosk
                    </div>
                </div>
                <div className="options">
                    <Link to="/docs">
                        <div className="Text1">
                            Documentation
                        </div>
                    </Link>
                        <div className="spacer">

                        </div>
                        <div className="Text1">
                            Help
                        </div>
                    </div>
        </div>
        <div className="sub-panel-wrap">
            <div className="side-panel">

                <div className="panel-selector wallet" onClick={clickToSetActiveWallet}>
                        <div className="graphic">
                            <img src="/img/WalletSmall.png" />
                        </div>
                        <div className="Title100">
                            Wallet
                        </div>
                </div>
                <div className="panel-selector api-keys" onClick={clickToSetActiveAPIKeys}>
                        <div className="graphic">
                            <img src="/img/KeySmall.png" />
                        </div>
                        <div className="Title100">
                            API Keys
                        </div>
                </div>
                <div className="panel-selector billing" onClick={clickToSetActiveSettings}>
                    <div className="graphic">
                        <img src="/img/GearSmall.png" />
                    </div>
                    <div className="Title100">
                            Settings
                        </div>
                </div>
                {moduleDisplayed === 'settings' ? 
                    <SettingsDropdown 
                    clickSettingsOrganization={clickSettingsOrganization}
                    clickSettingsTeam={clickSettingsTeam}
                    clickSettingsBilling={clickSettingsBilling}
                    clickSettingsProfile={clickSettingsProfile}
                    settingDisplayed={settingDisplayed}
                    /> :
                    ''
                }
                {/* <div className="panel-selector documentation" onClick={clickToSetActiveDocs} >
                <div className="graphic">
                        
                        </div>
                        Documentation
                </div> */}
            </div>
            <div className="main-panel">
                {activeModule}
            </div>
        </div>

    </div>

);

}

export default Console;




function SettingsDropdown({clickSettingsBilling,clickSettingsOrganization,clickSettingsProfile,clickSettingsTeam,settingDisplayed}){
    let orgDisplayed = false;
    let teamDisplayed = false;
    let billingDisplayed = false;
    let profileDisplayed = false;
    if(settingDisplayed === "organization"){
        orgDisplayed = true;
    }
    if(settingDisplayed === "team"){
        teamDisplayed = true;
    }
    if(settingDisplayed === "billing"){
        billingDisplayed = true;
    }
    if(settingDisplayed === "profile"){
        profileDisplayed = true;
    }
    return(
        <div className={`settings-dropdown`}>
            <div className={`dropdown-title ${orgDisplayed ? 'selected' : ''}`}
                onClick={clickSettingsOrganization}
            >
                Organization
            </div>
            <div className={`dropdown-title ${teamDisplayed ? 'selected' : ''}`}
                onClick={clickSettingsTeam}
            >
                Team
            </div>
            <div className={`dropdown-title ${billingDisplayed ? 'selected' : ''}`}
                onClick={clickSettingsBilling}
            >
                Billing
            </div>
            <div className={`dropdown-title ${profileDisplayed ? 'selected' : ''}`}
                onClick={clickSettingsProfile}
            >
                Profile
            </div>
        </div>
    )
}