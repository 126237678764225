import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, {isCancel, AxiosError} from 'axios';

import { internal_api_href } from "../store";

export const registerAPIKey = createAsyncThunk(
  'api/registerAPIKey',
  async ({userToken,thisName}, { rejectWithValue }) => {
    try {
      // Perform the registration logic here using the apiKeyData
      const postData = {
        token:userToken,
        name:thisName
        };

        const config = {
            method: 'post',
            url: `${internal_api_href}api-registration`,
            headers: { 
              'Content-Type': 'application/json',
            },
            data: postData
          };

          const result = await axios(config);
          return result;
      // Return the registered API key or relevant data if successful
      
    } catch (error) {
      // Handle any errors that occur during the registration process

      // If necessary, you can reject the promise with a specific error value using rejectWithValue()
      return rejectWithValue(error);
    }
  }
);


export const getAPIDetails = createAsyncThunk(
    'api/getAPIDetails',
    async (userToken, { rejectWithValue }) => {
      try {
        // Perform the registration logic here using the apiKeyData
        const postData = {
          token:userToken,
          };
  
          const config = {
              method: 'post',
              url: `${internal_api_href}get-api-details`,
              headers: { 
                'Content-Type': 'application/json',
              },
              data: postData
            };
  
            const result = await axios(config);
            //{data,status,statusText,headers,config}
            return result;
        // Return the registered API key or relevant data if successful
        
      } catch (error) {
        // Handle any errors that occur during the registration process
  
        // If necessary, you can reject the promise with a specific error value using rejectWithValue()
        return rejectWithValue(error);
      }
    }
  );

  export const deleteOneAPIDetail = createAsyncThunk(
    'api/deleteOneAPIDetail',
    async ({userToken,id}, { rejectWithValue }) => {
      try {
        // Perform the registration logic here using the apiKeyData
        const postData = {
          token:userToken,
          id:id
          };
  
          const config = {
              method: 'post',
              url: `${internal_api_href}api-delete-one`,
              headers: { 
                'Content-Type': 'application/json',
              },
              data: postData
            };
  
            const result = await axios(config);
            //{data,status,statusText,headers,config}
            console.log(result);
            return result;
        // Return the registered API key or relevant data if successful
        
      } catch (error) {
        // Handle any errors that occur during the registration process
  
        // If necessary, you can reject the promise with a specific error value using rejectWithValue()
        return rejectWithValue(error);
      }
    }
  );


export const apiSlice = createSlice({
    name:'api',
    initialState:{
        apiRegistrationLoading:false,
        apiRegistrationStatus:{success:false,error:0,attempt:false},
        apiRegistrationSecretOneTime:'',
        apiDetails:[],
        apiDetailsLoading:false,
        apiDetailsStatus:{
          attempt:false,
          success:false,
          error:0
        },
        apiDetailsErrorStatus:0,
        apiDeleteOneLoading:false,
        apiDeleteOneStatus:{success:false,attempt:false,error:0}
    },
    reducers:{
            removeOneTimeKey: (state) => {
                state.apiRegistrationSecretOneTime = null;
                state.apiRegistrationStatus.success = false;
                state.apiRegistrationStatus.attempt = false;
                state.apiRegistrationStatus.error = false;
            },
            resetDeleteOneDetails: (state) => {
                state.apiDeleteOneLoading = false;
                state.apiDeleteOneStatus = {success:false,attempt:false,error:0};
            },
            refreshGetAPIDetails: (state) => {
              state.apiDetailsStatus = {
                attempt:false,
                success:false,
                error:0
              }
            },
            refreshRegisterDetails: (state) => {
              state.apiRegistrationStatus = {
                attempt:false,
                success:false,
                error:0
              }
            }
    },
    extraReducers:(builder) => {
        builder
          .addCase(getAPIDetails.pending, (state, action) => {
            // Handle pending action, if needed
            state.apiDetailsLoading = true;
          })
          .addCase(getAPIDetails.fulfilled, (state, action) => {
            // Handle fulfilled action
            // You can access the response data using action.payload
            state.apiDetailsLoading = false;
            const {data} = action.payload;
            state.apiDetails = data.apiDetails;
            state.apiDetailsStatus = {
              attempt:true,
              success:true,
              error:0
            }
          })
          .addCase(getAPIDetails.rejected, (state, action) => {
            // Handle rejected action
            // You can access the error using action.error
            state.apiDetailsLoading = false;
            const { response } = action.payload;
            const {data,status} = response;
            state.apiDetailsStatus = {
              attempt:true,
              success:false,
              error:status
            }
          })


          .addCase(registerAPIKey.pending, (state, action) => {
            // Handle pending action, if needed
            state.apiRegistrationLoading = true;
          })
          .addCase(registerAPIKey.fulfilled, (state, action) => {
            // Handle fulfilled action
            // You can access the response data using action.payload
            const {insert,oneTimeKey} = action.payload.data;
            state.apiRegistrationStatus.attempt = true;
            state.apiRegistrationStatus.success = true;
            state.apiRegistrationSecretOneTime = oneTimeKey;
            state.apiRegistrationLoading = false;
          })
          .addCase(registerAPIKey.rejected, (state, action) => {
            // Handle rejected action
            // You can access the error using action.error
            const { response } = action.payload;
            const {data,status} = response;
            state.apiRegistrationStatus.attempt = true;
            state.apiRegistrationStatus.error = status;
            state.apiRegistrationLoading = false;
          })


          .addCase(deleteOneAPIDetail.pending, (state, action) => {
            // Handle pending action, if needed
            state.apiDeleteOneLoading = true;
          })
          .addCase(deleteOneAPIDetail.fulfilled, (state, action) => {
            // Handle fulfilled action
            // You can access the response data using action.payload
            state.apiDeleteOneStatus.attempt = true;
            state.apiDeleteOneStatus.success = true;
            state.apiDeleteOneLoading = false;
          })
          .addCase(deleteOneAPIDetail.rejected, (state, action) => {
            // Handle rejected action
            // You can access the error using action.error
            const { response } = action.payload;
            const {data,status} = response;
            state.apiDeleteOneStatus.attempt = true;
            state.apiDeleteOneStatus.success = false;
            state.apiDeleteOneStatus.error = status;
            state.apiDeleteOneLoading = false;
          })
    }
});


export default apiSlice.reducer;
export const { removeOneTimeKey,resetDeleteOneDetails, refreshGetAPIDetails, refreshRegisterDetails } = apiSlice.actions;