import logo from './logo.svg';
import './App.css';

import React, { useState, useCallback, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Navigate,
  Routes
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';


import Register from './pages/Register/Register';
import HomePage from './pages/Home/Home';
import API from './pages/API/API';
import Console from './pages/Console/Console';
import PostCheckout from './pages/PostCheckout/PostCheckout';
import Login from './pages/Login/Login';
import DocPage from './pages/Docs/Documentation';

import PrivacyPolicy from './shared/util/privacy-policy';
import TermsAndConditions from './shared/util/terms';

import ProtectiveConsoleWrapper from './shared/hooks/ProtectiveConsoleWrap';

import { checkUserInLocalStorage } from './shared/store/slices/userSlice';


function App() {
  let routes;
  const dispatch = useDispatch();
  const {userToken} = useSelector((store)=>store.users);
  console.log(userToken);
  useEffect(()=>{
    dispatch(checkUserInLocalStorage());
  },[]);

  if(userToken){
    routes = (
      <Routes>
        <Route path="/" element={<HomePage/>} />
        <Route path="/console" element={<Console />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/billing/:billing_param" element={<PostCheckout />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/docs" element={<DocPage />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    );
  }else{
    routes = (
      <Routes>
        <Route path="/" element={<HomePage/>} />
        <Route path="/console" element={<ProtectiveConsoleWrapper><Console /></ProtectiveConsoleWrapper>} />
        <Route path="/register" element={<Register />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/billing/:billing_param" element={<PostCheckout />} />
        <Route path="/login" element={<Login />} />
        <Route path="/docs" element={<DocPage />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    );
  }

  return (
      <Router>
                <main>{routes}</main>
              <div className="refuel"></div>
      </Router>
  );
}

export default App;
