import React, {useState,useCallback,useEffect} from 'react';
import ReactDOM from 'react-dom';
import axios, {isCancel, AxiosError} from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { registerAPIKey, getAPIDetails, deleteOneAPIDetail, removeOneTimeKey, resetDeleteOneDetails, refreshGetAPIDetails, refreshRegisterDetails } from '../../../shared/store/slices/apiSlice' 
import { setNavigateToBilling } from '../../../shared/store/slices/settingsSlice';
import Input from '../../../shared/components/FormElements/Input';
import { useForm } from '../../../shared/hooks/form-hook';
import { VALIDATOR_MINLENGTH, VALIDATOR_MAXLENGTH } from '../../../shared/util/validators';


import './API_Keys.css'

function API_Keys(){
    const [renderRegisterModal,setRenderRegisterModal] = useState(false);
    const [renderDeleteModal,setRenderDeleteModal] = useState(false);
    const [deleteModalDetails,setDeleteModalDetails] = useState();

    const [getAPIDetailsError,setGetAPIDetailsError] = useState(false);

    const dispatch = useDispatch();
    const APIDetails = useSelector((store)=> store.api.apiDetails);
    const apiDetailsLoading = useSelector((store)=> store.api.apiDetailsLoading);
    const apiDetailsStatus = useSelector((store)=> store.api.apiDetailsStatus);
    const userToken = useSelector((store) => store.users.userToken);
    const checkingLocalStorageAttempt = useSelector((store) => store.users.checkingLocalStorageAttempt);
    const billingDetails = useSelector((store) => store.users.userDetails.billingDetails);
    const walletLoaded = useSelector((store) => store.users.userDetails.walletLoaded);
    const paidStatus = billingDetails !== "" ? billingDetails.paidStatus : false;


    const ERROR_MESSAGES = {
        401:"Error retrieving user credentials. You may be signed out",
        403:"Server Error. Retry later",
        406:'Server Error. Retry later',
        503:"Server Error. Retry later",
        502:`Oops! It looks like we were disconnected. Please try reloading the page.`
    }


    function renderAPIRegisterModal(){
        if(APIDetails.length < 10){
            setRenderRegisterModal(true);
        }
    }

    function cancelAPIRegisterModal(){
        setRenderRegisterModal(false);
    }

    function postRegistrationClose(){
        setRenderRegisterModal(false);
        dispatch(removeOneTimeKey());
        dispatch(getAPIDetails(userToken));
    }

    function renderAPIDeleteModal(privateKey,id){
        setDeleteModalDetails({key:privateKey,id:id});
        setRenderDeleteModal(true);
    }
    function cancelDeleteModal(){
        setDeleteModalDetails();
        setRenderDeleteModal(false);
        dispatch(resetDeleteOneDetails());
        dispatch(getAPIDetails(userToken));
    }

    function handleNavToBilling(){
        dispatch(setNavigateToBilling());
    }


    useEffect(()=>{
        if(checkingLocalStorageAttempt){
            dispatch(getAPIDetails(userToken));
        }
        return () =>{
            dispatch(refreshGetAPIDetails());
        }
    },[]);

    useEffect(()=>{
        if(checkingLocalStorageAttempt){
            dispatch(getAPIDetails(userToken));
        }
    },[checkingLocalStorageAttempt]);


    useEffect(()=>{
        const {success,attempt,error} = apiDetailsStatus;
        if(!success && attempt){
            if(error !== 0){
                setGetAPIDetailsError(true);
            }
        }
    },[apiDetailsStatus]);






    let API_Detail_Rows = [];
    for(const apiDetail of APIDetails){
        const {name,privateKey,created,last_use,id} = apiDetail;
        API_Detail_Rows.push(
            <div className="table-body-row">
                <div className="table-col1">
                    {name}
                </div>
                <div className="table-col2">
                    {privateKey}
                </div>
                <div className="table-col3">
                    {created}
                </div>
                <div className="table-col4">
                    {last_use}
                </div>
                <div className="table-col5">
                    <div className="delete-wrap" onClick={() => renderAPIDeleteModal(privateKey,id)}>
                        X
                    </div>
                </div>
            </div>
        );
    }
    if(APIDetails.length === 0){
        API_Detail_Rows.push(
            <div className="table-body-row">
                <div className="table-col1">
                    
                </div>
                <div className="table-col2">
                    
                </div>
                <div className="table-col3">
                    
                </div>
                <div className="table-col4">
                    
                </div>
                <div className="table-col5">
                    
                </div>
            </div>
        );
    }


    const APILoadingRow =      <div className="table-body-row">
                                    <div className="table-col1">
                                        <div className="load-glyph">
                                            <div className="load-background">

                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-col2">
                                        <div className="load-glyph">
                                            <div className="load-background">
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-col3">
                                        
                                    </div>
                                    <div className="table-col4">

                                    </div>
                                    <div className="table-col5">

                                    </div>
                                </div>;

    return(
        <div className="API-MODULE">
            {renderRegisterModal ? 
            <RegisterModal 
                cancelAPIModal={cancelAPIRegisterModal}
                postRegistrationClose={postRegistrationClose}
            /> : ''}
            {renderDeleteModal ?
                <DeleteModal
                    deleteModalDetails={deleteModalDetails}
                    cancelDeleteModal={cancelDeleteModal}
                />
                :
                ""
            }
            <div className="title">
                <div className="Title2">
                    API Keys
                </div>
            </div>
            <div className="module-body">
                <div className="body-text1">
                Your secret API keys are listed below. Please note that we do not display your secret API keys again after you generate them.
                </div>
                <div className="body-text1">
                Do not share your API key with others, or expose it in the browser or other client-side code. In order to protect the security of your account, BitKiosk may also automatically disable any API key that we've found has leaked publicly.
                </div>
                {billingDetails === "" ?
                            <React.Fragment>
                                <div className="body-text1" style={{ fontWeight: 700 , color:"#2baf3b"}}>
                                Subscription Required for API Access. Manage your subscription in Settings - Billing
                                </div> 
                                <button onClick={handleNavToBilling}>START SUBSCRIPTION</button>
                            </React.Fragment>
                        :
                        !paidStatus ?
                            <div className="body-text2" style={{ fontWeight: 700 , color:"#2baf3b"}}>
                            Active subscription required for API Access. Your subscription has gone into an unpaid status. API/Wallet usage temporarily unavailable.
                            </div> 
                        :
                        !walletLoaded ?
                            <div className="body-text1" style={{ fontWeight: 700 , color:"#2baf3b"}}>
                                Register an API key below to generate a wallet
                            </div>
                        :
                        APIDetails.length === 0 ?
                                <div className="body-text1" style={{ fontWeight: 700 , color:"#2baf3b"}}>
                                    Generate an API Key below to gain access to BitKiosk API
                                </div>
                                :
                                ""
                }
                <div className="apiKey-table">
                    <div className="table-title-row">
                        <div className="table-col1">
                            <div className="Title4">
                                NAME
                            </div>
                        </div>
                        <div className="table-col2">
                          <div className="Title4">
                                SECRET KEY
                            </div>
                        </div>
                        <div className="table-col3">
                        <div className="Title4">
                                CREATED
                                </div>
                        </div>
                        <div className="table-col4">
                        <div className="Title4">
                                LAST  USED
                                </div>
                        </div>
                        <div className="table-col5">

                        </div>
                    </div>
                    <div className="table-body">
                        {apiDetailsLoading ?
                                APILoadingRow
                                :
                                API_Detail_Rows
                        }
                        {getAPIDetailsError ?
                            <div className="Title52 error">
                                {ERROR_MESSAGES[apiDetailsStatus.error]}
                            </div>
                            : ''
                        }
                    </div>
                    <div className="table-additions">
                        {APIDetails.length === 10 ?
                            <div className="Title9">
                                You've reached the 10 limit max for API keys.
                            </div>
                            :
                            paidStatus ?
                            <button onClick={renderAPIRegisterModal}>+ Create new secret key</button>
                            : ''
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}








function RegisterModal({cancelAPIModal,postRegistrationClose}){
    const dispatch = useDispatch();
    const userToken = useSelector((store) => store.users.userToken);
    const apiRegistrationStatus = useSelector((store) => store.api.apiRegistrationStatus);
    const apiRegistrationLoading = useSelector((store) => store.api.apiRegistrationLoading);
    const apiRegistrationSecretOneTime = useSelector((store) => store.api.apiRegistrationSecretOneTime);
    const {success:apiRegSuccess,error:apiRegError,attempt:apiRegAttempt} = apiRegistrationStatus;
    const [formState, inputHandler, setFormData] = useForm(
        {
          name: {
            value: '',
            isValid: true
          },
        },
        true
      );

      function handleAPIRegistration(){
        //Check for shorter than 50 characters
        const thisName = formState.inputs.name.value;
        if(formState.isValid){
            dispatch(registerAPIKey({userToken,thisName}));
        }
    }


    const ERROR_MESSAGES = {
        401:"Error retrieving user credentials. You may be signed out",
        403:"Server Error. Retry later",
        406:'Server Error. Retry later',
        503:"Server Error. Retry later",
        502:`Oops! It looks like we were disconnected. Please try reloading the page.`
    }

    useEffect(()=>{
        return () =>{
            dispatch(refreshRegisterDetails());
        }
    },[]);


    const content =
    <div className="reg-modal-wrap" onClick={cancelAPIModal}>
        <div className="reg-modal-content" onClick={(e)=> e.stopPropagation()}>
            <div className="Title5">
                Create new secret key
            </div>
{ 
apiRegAttempt ?

    apiRegSuccess ?

        <React.Fragment>
            <div className="Title8">
            Please save this secret key somewhere safe and accessible. For security reasons, you won't be able to view it again. If you lose this secret key, you'll need to generate a new one.
            </div>
            <div className="secretkey-input-wrap">
                <input readOnly value={apiRegistrationSecretOneTime} />
                <button>Copy</button>
            </div>
            <div className="button-row">
                <button onClick={postRegistrationClose}>Okay</button>
            </div>
        </React.Fragment>

    :

        <React.Fragment>
            <div className="Title8 error">
                {ERROR_MESSAGES[apiRegError]}
            </div>
            <div className="button-row" style={{marginTop:"70px"}}>
                <button onClick={postRegistrationClose}>Okay</button>
            </div>
         </React.Fragment>

:

<React.Fragment>
<div className="name-optional">
    <div className="Title6">
        Name
    </div>
    <div className="Title7">
        {`(optional)`}
    </div>
</div>
<Input
                    element="input"
                    id="name"
                    type="text"
                    label=""
                    validators={[VALIDATOR_MINLENGTH(0),VALIDATOR_MAXLENGTH(40)]}
                    errorText="40 characters max"
                    onInput={inputHandler}
                    mismatchText=""
                    initialValid={true}
/>
<div className="button-row">
    <button className="cancel" onClick={cancelAPIModal} >Cancel</button>
    <button className="create" onClick={handleAPIRegistration} disabled={!formState.isValid}>
        {apiRegistrationLoading ?
            <div className="loading-circle">
                
            </div>
            :
            'Create New Secret Key'
        }
        
        </button>
</div>
</React.Fragment>
}



        </div>
    </div>

    ;
    return ReactDOM.createPortal(content, document.getElementById('modal'));
}


function DeleteModal({deleteModalDetails,cancelDeleteModal}){
    const userToken = useSelector((store) => store.users.userToken);
    const apiDeleteOneLoading = useSelector((store) => store.api.apiDeleteOneLoading);
    const apiDeleteOneStatus = useSelector((store) => store.api.apiDeleteOneStatus);
    const {key,id} = deleteModalDetails;
    const dispatch = useDispatch();
    function deleteThisAPISecretKey(id){
        dispatch(deleteOneAPIDetail({userToken,id}));
    }

    useEffect(()=>{
        const {success,attempt,error} = apiDeleteOneStatus;
        if(attempt && success){
            cancelDeleteModal();
        }
    },[apiDeleteOneStatus]);

    useEffect(()=>{
        return () => {
            dispatch(resetDeleteOneDetails());
        }
    },[]);


    const ERROR_MESSAGES = {
        401:"Error retrieving user credentials. You may be signed out",
        403:"Server Error. Retry later",
        406:'Server Error. Retry later',
        503:"Server Error. Retry later",
        502:`Oops! It looks like we were disconnected. Please try reloading the page.`
    }


    const content = 
    <div className="reg-modal-wrap" onClick={cancelDeleteModal}>
        <div className="reg-modal-content" onClick={(e)=> e.stopPropagation()}>
            <div className="Title5">
                Revoke secret key
            </div>
        
        {apiDeleteOneStatus.attempt ?

            !apiDeleteOneStatus.success ?
                
            <React.Fragment>
                <div className="Title9 error">
                    Failed to revoke this secret key. {ERROR_MESSAGES[apiDeleteOneStatus.error]}
                </div>
                <div className="button-row">
                    <button onClick={cancelDeleteModal}>Okay</button>
                </div>
            </React.Fragment>

            :

            <React.Fragment>
                <div className="Title9">
                    Secret key revoked
                </div>
                <div className="button-row">
                    <button onClick={cancelDeleteModal}>Okay</button>
                </div>
            </React.Fragment>

:

<React.Fragment>
<div className="Title8">
        This API key will immediately be disabled. API requests made using this key will be rejected, which could cause any systems still depending on it to break. Once revoked, you'll no longer be able to view or use this API key.
        </div>
        <input className="revoke-input" readOnly value={key} />
        <div className="button-row">
            <button className="cancel" onClick={cancelDeleteModal}>Cancel</button>
            <button className="create" onClick={()=> deleteThisAPISecretKey(id)}>
                {apiDeleteOneLoading ?
                    <div className="loading-circle">
                        
                    </div> :
                    'Revoke secret key'
                }
                </button>
        </div>
</React.Fragment>

        }



        </div>
    </div>


    return ReactDOM.createPortal(content, document.getElementById('modal'));
}













export default API_Keys;