import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, {isCancel, AxiosError} from 'axios';

import { internal_api_href } from "../store";

import { setUserDetails } from "./userSlice";


export const googleBackendRegister = createAsyncThunk(
    'google/googleBackendRegister',
    async ({registrationKey,clientId,credential}, { dispatch, rejectWithValue }) => {
      try {
        // Perform the registration logic here using the apiKeyData
        const postData = {
          token:registrationKey,
          clientId:clientId,
          credential:credential
          };
  
          const config = {
              method: 'post',
              url: `${internal_api_href}google-backend-register`,
              headers: { 
                'Content-Type': 'application/json',
              },
               data: postData
            };
  
            const result = await axios(config);
            //{data,status,statusText,headers,config}
            console.log(result);
            const {data} = result;
            dispatch(setUserDetails(data));
            return result;
        // Return the registered API key or relevant data if successful
        
      } catch (error) {
        // Handle any errors that occur during the registration process
  
        // If necessary, you can reject the promise with a specific error value using rejectWithValue()
        return rejectWithValue(error);
      }
    }
  );

  export const google_Login = createAsyncThunk(
    'google/google_Login',
    async ({clientId,credential}, { dispatch, rejectWithValue }) => {
      try {
        // Perform the registration logic here using the apiKeyData
        const postData = {
          clientId:clientId,
          credential:credential
          };
  
          const config = {
              method: 'post',
              url: `${internal_api_href}google-login`,
              headers: { 
                'Content-Type': 'application/json',
              },
               data: postData
            };
  
            const result = await axios(config);

            const {data} = result;
            dispatch(setUserDetails(data));
            return result;
        // Return the registered API key or relevant data if successful
        
      } catch (error) {
        // Handle any errors that occur during the registration process
  
        // If necessary, you can reject the promise with a specific error value using rejectWithValue()
        return rejectWithValue(error);
      }
    }
  );



export const googleSlice = createSlice({
    name:'google',
    initialState:{
        googleBackendRegisterLoading:false,
        googleBackendRegisterResponse:{
            status:false,
            attempt:false
        },
        googleBackendRegisterError:false,


        google_LoginLoading:false,
        google_LoginStatus:{
          success:false,
          attempt:false,
          error:0
        },


    },
    reducers:{
        refreshGoogleRegistrationAttempt: (state) => {
          state.googleBackendRegisterResponse = {
            status:false,
            attempt:false
          };
          state.googleBackendRegisterError = false;
        }
    },
    extraReducers:(builder) => {
        builder
          .addCase(googleBackendRegister.pending, (state, action) => {
            // Handle pending action, if needed
            state.googleBackendRegisterLoading = true;
          })
          .addCase(googleBackendRegister.fulfilled, (state, action) => {
            // Handle fulfilled action
            // You can access the response data using action.payload
            state.googleBackendRegisterLoading = false;
            const {data,status} = action.payload;
            if(status === 200){
              state.googleBackendRegisterResponse = {
                status:true,
                attempt:true
              }
            }
          })
          .addCase(googleBackendRegister.rejected, (state, action) => {
            // Handle rejected action
            // You can access the error using action.error
            state.googleBackendRegisterLoading = false;
            console.log(action.payload);
            state.googleBackendRegisterResponse = {
              status:false,
              attempt:false
            }
          })



          .addCase(google_Login.pending, (state, action) => {
            // Handle pending action, if needed
            state.google_LoginLoading = true;
          })
          .addCase(google_Login.fulfilled, (state, action) => {
            // Handle fulfilled action
            // You can access the response data using action.payload
            state.google_LoginLoading = false;
            const {data,status} = action.payload;
            if(status === 200){
              state.google_LoginStatus = {
                success:true,
                attempt:true,
                error:0
              }
            }
          })
          .addCase(google_Login.rejected, (state, action) => {
            // Handle rejected action
            // You can access the error using action.error
            state.google_LoginLoading = false;
            console.log(action.payload);
            state.google_LoginStatus = {
              success:false,
              attempt:true,
              error:1
            }
          })
    }
});

export default googleSlice.reducer;
export const {refreshGoogleRegistrationAttempt} = googleSlice.actions;