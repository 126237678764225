import React, {useState,useCallback,useEffect} from 'react';
import axios, {isCancel, AxiosError} from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import './Documentation.css'

function Documentation(){
    return("Documentation")
}

export default Documentation;