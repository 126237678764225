import React, {useState,useCallback,useEffect} from 'react';
import ReactDOM from 'react-dom';
import axios, {isCancel, AxiosError} from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Elements, useStripe, useElements, CardElement,   CardNumberElement,
    CardExpiryElement,
    CardCvcElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { refreshBasicSettings, 
    refreshBillingSettings, 
    initializePaymentLink_InitialSubscription, 
    addPaymentMethod1,addPaymentMethod2, 
    refreshAddPaymentMethodStatus, 
    removePaymentMethod, 
    refreshRemovePaymentMethodStatus,
    makePaymentMethodDefault,
    refreshMakePaymentMethodDefaultStatus,
    refreshInitializeSubscriptionLink
 } from '../../../shared/store/slices/settingsSlice';

import Input from '../../../shared/components/FormElements/Input';
import { useForm } from '../../../shared/hooks/form-hook';
import { VALIDATOR_MINLENGTH, VALIDATOR_MAXLENGTH } from '../../../shared/util/validators';

import './Settings.css'


const stripePromise = loadStripe('pk_test_51IJYMYL3kvt3UaByatsvcCAhOfzEqABTWfVw3JQ09JzFmLnz0DSjA8dHBsoyxumzNv74Yle1jMcwCbCGKEzSUfU500c6BiEc6R');

function Settings({settingDisplayed}){
    const dispatch = useDispatch();
    const userToken = useSelector((store) => store.users.userToken);
    const checkingLocalStorageAttempt = useSelector((store) => store.users.checkingLocalStorageAttempt);

    useEffect(()=>{
        if(checkingLocalStorageAttempt){
            dispatch(refreshBasicSettings({userToken}));
        }
    },[]);

    useEffect(()=>{
        if(checkingLocalStorageAttempt){
            dispatch(refreshBasicSettings({userToken}));
        }
    },[checkingLocalStorageAttempt]);

    let activelyDisplayed;
    if(settingDisplayed === "organization"){
        activelyDisplayed = <OrgSetting />;
    }
    if(settingDisplayed === "team"){
        activelyDisplayed = <TeamSetting />;
    }
    if(settingDisplayed === "billing"){
        activelyDisplayed = <BillingSetting />;
    }
    if(settingDisplayed === "profile"){
        activelyDisplayed = <ProfileSetting />;
    }
    return(
        <div className="SETTINGS-MODULE">
            {activelyDisplayed}
        </div>
    );
}

export default Settings;



function OrgSetting(){
    const userDetails = useSelector((store) => store.users.userDetails);
    const {orgID,orgName} = userDetails;
    const [formState, inputHandler, setFormData] = useForm(
        {
          org_name: {
            value: '',
            isValid: false
          },
          org_id: {
            value:'',
            isValid:true
          }
        },
        false
      );

        useEffect(() => {
            const inputData = {
                ...formState.inputs,
                org_id:{
                    value:orgID,
                    isValid:true,
                }
            }
            setFormData(inputData,true);
        }, [orgID, setFormData]);

        useEffect(()=>{
            console.log(formState);
        },[formState]);


      function clickSaveOrgDetails(){
        console.log(formState);
      }

    return(
        <React.Fragment>
            <div className="title">
                <div className="Title2">
                    Organization
                </div>
            </div>
            <div className="module-body">
                <div className="Title21">
                    Details
                </div>
                <div className="Title22">
                    Organization name
                </div>
                <div className="Title23">
                    Human-friendly label for your organization, shown in user interfaces
                </div>
                <Input
                    element="input"
                    id="org_name"
                    type="text"
                    label=""
                    validators={[VALIDATOR_MINLENGTH(1),VALIDATOR_MAXLENGTH(40)]}
                    errorText=""
                    onInput={inputHandler}
                    mismatchText=""
                    initialValid={true}
                    initialValue={`${orgName ? orgName : 'Personal'}`}
                />

                <div className="Title22">
                    Organization ID
                </div>
                <div className="Title23">
                    Identifier for this organization used in API requests. This is your public key
                </div>
                <Input
                    element="input"
                    id="org_id"
                    type="text"
                    label=""
                    validators={[]}
                    errorText="40 characters max"
                    onInput={inputHandler}
                    mismatchText=""
                    initialValid={true}
                    initialValue={orgID}
                    readonly={true}
                />
                <button id="org-save" onClick={clickSaveOrgDetails}>Save</button>
            </div>
        </React.Fragment>
    )
}

function TeamSetting(){

    
    return(
        <React.Fragment>
            <div className="title">
                <div className="Title2">
                    Team
                </div>
            </div>
            <div className="module-body">

            </div>
        </React.Fragment>
    )
}




function BillingOverview({setOptionSelected,setRenderInitPaymentModal}){  
    const billingSettingsLoading = useSelector((store) => store.settings.billingSettingsLoading);
    const billingSettingsStatus = useSelector((store) => store.settings.billingSettingsStatus);
    const {tier,billingDetails} = useSelector((store) => store.users.userDetails);

    // let currentInvoice;
    // if (Object.keys(billingDetails).length === 0 && billingDetails.constructor === Object) {
    //     if(tier === 0){
    //         currentInvoice = "$5.00"
    //     }
    // }else{

    // }


    function handleGetStartedButton(){
        setRenderInitPaymentModal(true);
        // setTimeout(()=>{
        //     setRenderInitPaymentModal(false);
        // },10000);
    }

    let invoiceContent;
    //Before any cards added or initialized
    if(billingDetails === ''){
        if(tier === 0){
            invoiceContent = 
                <React.Fragment>
                    <div className="Title43">
                        Initial invoice
                    </div>
                    <div className="Title44">
                            $5.00
                    </div> 
                    <div className="Title45">
                        Initial payment required to create your wallet.<br/>
                        Thereafter, you will be billed monthly<br/>
                    </div>
                    <div className="button-row">
                        <button onClick={handleGetStartedButton}>Get started</button>
                    </div>
                </React.Fragment>
        }
    }else{
        console.log(billingDetails);
        //Destructure details
        const {paidStatus,nextInvoiceDate,monthlyFeeCents,invoiceHistory,paymentMethods} = billingDetails;
        let thisNextInvoiceDate;
        let within10 = false;
        if (nextInvoiceDate) {
            const date = new Date(nextInvoiceDate * 1000);
            const month = date.toLocaleString('default', { month: 'long' });
            const day = date.getDate();
            thisNextInvoiceDate = `${month} ${day}`;
    
            const currentTime = new Date();
            const timeDifference = date.getTime() - currentTime.getTime();
            const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
    
            // Check if the difference is within 10 business days
            if (daysDifference >= 0 && daysDifference <= 10) {
                within10 = true;
            }
        }
        let feeInUSD;
        if (monthlyFeeCents) {
            const feeInCents = monthlyFeeCents / 100;
            feeInUSD = feeInCents.toFixed(2);
        }

        if(paidStatus){
            if(within10){
                //Payment due in 10 days
                invoiceContent =
                <React.Fragment>
                    <div className="Title43">
                    Pending invoice
                    </div>
                    {billingSettingsLoading ? 
                                    <div className="Title44 hidden">
                                            HIDDEN
                                    </div> 
                                : 
                                    <div className="Title44">
                                        ${feeInUSD}
                                    </div> 
                    }
                    <div className="Title45">
                        Your next payment of ${feeInUSD} will be billed on {thisNextInvoiceDate}.
                    </div>
                </React.Fragment>
            }else{
                //Payment due outside 10 day window
                invoiceContent =
                <React.Fragment>
                    <div className="Title43">
                    Pending invoice
                    </div>
                    {billingSettingsLoading ? 
                                    <div className="Title44 hidden">
                                            HIDDEN
                                    </div> 
                                : 
                                    <div className="Title44">
                                        $0.00
                                    </div> 
                    }
                    <div className="Title45">
                        Account up-to-date. Your next payment of ${feeInUSD} will be billed on {thisNextInvoiceDate}.
                    </div>
                </React.Fragment>
            }
        }else{
            //PAYMENT OVERDUE
            invoiceContent =
            <React.Fragment>
                <div className="Title43">
                Overdue invoice
                </div>
                {billingSettingsLoading ? 
                                <div className="Title44 hidden">
                                        HIDDEN
                                </div> 
                            : 
                                <div className="Title44">
                                        ${feeInUSD}
                                </div> 
                }
                <div className="Title45">
                    Your BitKiosk payment is overdue. ${feeInUSD} was due on {thisNextInvoiceDate}. Your wallet will be taken offline until account in good standing. 
                </div>
            </React.Fragment>
        }
    }
    //After card is added, next payment due on x date


    return(
        <div className="billing-overview">
            <div className="Title42">
                Pay as you go
            </div>
            <div className="invoice-wrapper">
                {invoiceContent}
            </div>
            <div className="other-billing-actions">
                <div className="other-payment-methods">
                    <div className="Title47" onClick={()=> setOptionSelected(1)}>
                        Payment methods
                    </div>
                </div>
                <div className="other-billing-history">
                    <div className="Title47" onClick={()=> setOptionSelected(2)}>
                        Billing history
                    </div>
                </div>
            </div>
            <div className="invoice-memo">
                <div className="Title46">
                    Invoice amount is the cost associated with running and managing your bitcoin wallet for the calendar month ahead. 
                    Each transaction processed by your BitKiosk wallet has a standard transaction fee that deducts from your invoice amount. 
                    Once your BitKiosk transaction fees exceed the cost to manage your server for the month, your invoice will be fully paid. 
                </div>
            </div>
            <div className="billing-graphic-wrapper">

            </div>
        </div>
    );
}


function PaymentMethods({setRenderInitPaymentModal}) {
    const { tier, billingDetails } = useSelector((store) => store.users.userDetails);
    const paymentMethods = billingDetails !== "" ? billingDetails.paymentMethods : [];

  const [addPaymentMethodModal, setAddPaymentMethodModal] = useState(false);
  const [removePaymentMethodModal,setRemovePaymentMethodModal] = useState({
        open:false,
        brand:'',
        exp_month:0,
        exp_year:0,
        last4:'',
        id:''
    });
    const [makeDefaultModal,setMakeDefaultModal] = useState({
        open:false,
        brand:'',
        exp_month:0,
        exp_year:0,
        last4:'',
        id:''
    })



  function handleAddModal_Method() {
        setAddPaymentMethodModal(true);
  }

  function handleRemoveModal_Method() {
        setAddPaymentMethodModal(false);
  }


  function handleRPM_Open(brand,month,year,last4,id){
        setRemovePaymentMethodModal({
            open:true,
            brand:brand,
            exp_month:month,
            exp_year:year,
            last4:last4,
            id:id
        });
  }
  function handleRPM_Close(){
        setRemovePaymentMethodModal({
            open:false,
            brand:'',
            exp_month:0,
            exp_year:0,
            last4:'',
            id:''
        });
  }


  function handleMDM_Open(brand,month,year,last4,id){
    setMakeDefaultModal({
        open:true,
        brand:brand,
        exp_month:month,
        exp_year:year,
        last4:last4,
        id:id
    })
  }

  function handleMDM_Close(brand,month,year,last4,id){
    setMakeDefaultModal({
        open:false,
        brand:'',
        exp_month:0,
        exp_year:0,
        last4:'',
        id:''
    })
  }



const PaymentMethodsComponent = () => {
  const reversedPaymentMethods = [...paymentMethods].reverse();

  // Find the index of the default payment method
  const defaultIndex = reversedPaymentMethods.findIndex(method => method.default);

  // Move the default payment method to the beginning of the array
  if (defaultIndex !== -1) {
    const defaultMethod = reversedPaymentMethods.splice(defaultIndex, 1)[0];
    reversedPaymentMethods.unshift(defaultMethod);
  }

  return (
    <div className="current-methods-wrap">
      {reversedPaymentMethods.map((method, index) => (
        <div className="individual-method" key={index}>
          {method.default ? (
            ''
          ) : (
            <div
              className="delete-method-icon"
              onClick={() =>
                handleRPM_Open(
                  method.brand,
                  method.exp_month,
                  method.exp_year,
                  method.last4,
                  method.paymentMethodID
                )
              }
            >
              X
            </div>
          )}
          <div className="left-div">
            <p>Brand: {method.brand}</p>
            {method.default ? 'Default' : 
                    <div className="Title64" onClick={()=>{handleMDM_Open(
                        method.brand,
                        method.exp_month,
                        method.exp_year,
                        method.last4,
                        method.paymentMethodID
                    )}}>
                    MAKE DEFAULT
                </div>
            }
          </div>
          <div className="right-div">
            <p>....{method.last4}</p>
            <p>
              Expires {method.exp_month}/{method.exp_year}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

  const clickInitialPayment = () => {
        setRenderInitPaymentModal(true);
  }



  return (
    <div className="payment-methods">
      {addPaymentMethodModal ?
        <Elements stripe={stripePromise}>
          <AddPaymentMethodModal
            handleRemoveModal_Method={handleRemoveModal_Method}
          />
        </Elements>
        :
        ''
      }
      {removePaymentMethodModal.open ?
        <RemovePaymentMethodModal
            handleRPM_Close={handleRPM_Close}
            removePaymentMethodModal={removePaymentMethodModal}
        />
        : ''
      }
        {makeDefaultModal.open ?
            <MakeDefaultPaymentMethodModal
                handleMDM_Close={handleMDM_Close}
                makeDefaultModal={makeDefaultModal}
            />
            : ''
        }


      <PaymentMethodsComponent />
      {!billingDetails || billingDetails.length === 0 ?
            <div className="additional-method-wrap" onClick={clickInitialPayment}>
                    <div className="Title60" >
                        Get Started
                    </div>
            </div>
          :
                <div className="additional-method-wrap" onClick={handleAddModal_Method}>
                    <div className="Title60" >
                        Add new card +
                    </div>
                </div>
        }
    </div>
  )
}


function AddPaymentMethodModal({handleRemoveModal_Method}){
    const dispatch = useDispatch();
    const userToken = useSelector((store) => store.users.userToken);
    const addPaymentMethodLoading = useSelector((store) => store.settings.addPaymentMethodLoading);
    const addPaymentMethod1Status = useSelector((store) => store.settings.addPaymentMethod1Status);
    const addPaymentMethod2Status = useSelector((store) => store.settings.addPaymentMethod2Status);
    const stripe = useStripe();
    const elements = useElements();

    const [errorMessage,setErrorMessage] = useState('');
    //400 - Initial Header from Nginx Rejection
    //*401 - Invalid Token, user not logged in, proceed to log user out
    //*403 - Forbidden
    //*406 - No user found
    //408 - Transaction went unpaid
    //409 - Email already in use
    //410 - Too many API, cannot have more than 10 API

    //*503 - MongoDB Server Unavailable / bcrypt not encrypting password
    //*502 - 

 useEffect(() => {
   // Run your effect here
 
   // Return a cleanup function
   return () => {
     dispatch(refreshAddPaymentMethodStatus());
   };
 }, []);



 const ERROR_MESSAGES = {
    401:"Error retrieving user credentials. You may be signed out",
    403:"Server Error. Retry later",
    406:'Server Error. Retry later',
    503:"Server Error. Retry later",
    502:`Oops! It looks like we were disconnected. Please try reloading the page.`
}


 useEffect(() => {
    const {success,attempt,secret,error} = addPaymentMethod1Status;
    async function confirmCardSetup(){
        if(success && attempt){
            const cardElement = elements.getElement(CardElement);
            const { setupIntent, error } = await stripe.confirmCardSetup(secret, {
                payment_method: {
                card: cardElement,
                billing_details: {
                    // Include any billing details
                    //name: 'Jenny Rosen',
                },
                },
            });
            if (error) {
                console.log(error.message);
                setErrorMessage(ERROR_MESSAGES[503]);
                setTimeout(()=>{
                    handleRemoveModal_Method();
                },1500);
                // Handle errors here
              } else {
                console.log('SetupIntent created:', setupIntent.id);
                // Handle successful setup here
                dispatch(addPaymentMethod2({userToken,setupIntent}));
              }
        }else{
            //ERROR MESSAGES
            console.log(error);
            if(error === 0){
                return;
            }else{
                setErrorMessage(ERROR_MESSAGES[error]);
                setTimeout(()=>{
                    handleRemoveModal_Method();
                },1500);
            }


        }
    }

    confirmCardSetup();
 },[addPaymentMethod1Status])

 useEffect(()=>{
    const {success,attempt,error} = addPaymentMethod2Status;
    if(success && attempt){
        //Success UX
        handleRemoveModal_Method();
    }else{
        if(error !== 0){
            setErrorMessage(ERROR_MESSAGES[error]);
            setTimeout(()=>{handleRemoveModal_Method()},1000);
        }
    }
 },[addPaymentMethod2Status]);


    async function handleSubmit(event){
        event.preventDefault();
        if (!stripe || !elements || addPaymentMethodLoading || addPaymentMethod1Status.success && addPaymentMethod1Status.attempt) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
          }
      
        const cardElement = elements.getElement(CardElement);

        dispatch(addPaymentMethod1({userToken}));
    }


    const CARD_ELEMENT_OPTIONS = {
        iconStyle:'solid',
        style: {
        base: {
            iconColor: '#363636',
            color: '#363636',
            fontWeight: 700,
            fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
            fontSize: '18px',
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': {
                color: '#363636',
            },
            '::placeholder': {
                color: '#a8a8a8',
            },
            },
            invalid: {
            iconColor: '#cd4e20',
            color: '#cd4e20',
            },
          complete: {
            color: "black",
          }
        },
      };

    const content =     
                            <div className="add-payment-method-wrap" onClick={handleRemoveModal_Method}>
                                <div className="inner-content" onClick={(e)=> e.stopPropagation()}>
                                    <div className="Title57" onClick={handleRemoveModal_Method}>
                                        cancel
                                    </div>
                                <form>
                                    <div className={`card-element-container ${addPaymentMethodLoading ? 'active' : ''} 
                                        ${addPaymentMethod1Status.success && addPaymentMethod1Status.attempt ? 'active' : ''}
                                        ${addPaymentMethod1Status.error !== 0 ? 'error' : ''}
                                        ${addPaymentMethod2Status.error !== 0 ? 'erorr' : ''}
                                    `}>
                                        <div className="error-text">
                                                {errorMessage}
                                        </div>
                                        <CardElement options={CARD_ELEMENT_OPTIONS} />
                                    </div>
                                    <div className="button-row">
                                        <div className="stripe-promotional-wrap">
                                        <div className="Title58">
                                            Powered by
                                        </div>
                                        <img src="/Stripe-logo.png" />
                                        </div>
                                    <button type="button" disabled={!stripe} onClick={handleSubmit} >
                                        Add Card
                                    </button>
                                    </div>

                                    </form>
                                </div>
                            </div>
                    

    return ReactDOM.createPortal(content, document.getElementById('modal'));
}

function RemovePaymentMethodModal({handleRPM_Close,removePaymentMethodModal}){
    const dispatch= useDispatch();
    const removePaymentMethodLoading = useSelector((store) => store.settings.removePaymentMethodLoading);
    const removePaymentMethodStatus = useSelector((store) => store.settings.removePaymentMethodStatus);

    const details = removePaymentMethodModal;
    const {brand,exp_month,exp_year,last4,id} = details;

    const [errorMessage,setErrorMessage] = useState(0);
    const [errorText,setErrorText] = useState('');
    //400 - Initial Header from Nginx Rejection
    //*401 - Invalid Token, user not logged in, proceed to log user out
    //*403 - Forbidden
    //*406 - No user found
    //408 - Transaction went unpaid
    //409 - Email already in use
    //410 - Too many API, cannot have more than 10 API

    //*503 - MongoDB Server Unavailable / bcrypt not encrypting password
    //*502 - 

    const ERROR_MESSAGES = {
        401:"Error retrieving user credentials. You may be signed out",
        403:"Server Error. Retry later",
        406:'Server Error. Retry later',
        503:"Server Error. Retry later",
        502:`Oops! It looks like we were disconnected. Please try reloading the page.`
    }
    


    const userToken = useSelector((store) => store.users.userToken);

    const handleRemoval = () => {
        if(!removePaymentMethodLoading){
            dispatch(removePaymentMethod({userToken,paymentMethodID:id}));
        }
    }

    useEffect(()=>{
        const {success,attempt,error} = removePaymentMethodStatus;
        if(success && attempt){
            handleRPM_Close();
        }else{
            if(error !== 0){
                setErrorMessage(error);
                setErrorText(ERROR_MESSAGES[error]);
            }
        }
    },[removePaymentMethodStatus]);


    useEffect(()=>{
        return () => {
            dispatch(refreshRemovePaymentMethodStatus());
        }
    },[]);


    const content = <div className="remove-payment-method-wrap" onClick={handleRPM_Close}>
        <div className="inner-content" onClick={(e)=> e.stopPropagation()}>
            
                {errorMessage !== 0 ?
                    <div className="Title62 error">
                        {errorText}
                    </div>
                     : '' }
                    <div className="Title62">
                    Are you sure you want to delete this payment method?
                    </div>
            <div className="details-row">
                <div className="Title63">
                    {brand}
                </div>
                <div className="Title63">
                    Exp {exp_month}/{exp_year}
                </div>
                <div className="Title63">
                    ....{last4}
                </div>
            </div>
            <button onClick={handleRPM_Close} disabled={removePaymentMethodLoading || removePaymentMethodStatus.error !== 0}>Cancel</button>
            <button onClick={handleRemoval} disabled={removePaymentMethodLoading || removePaymentMethodStatus.error !== 0}>Remove card</button>
        </div>
    </div>
    return ReactDOM.createPortal(content, document.getElementById('modal'));
}

function MakeDefaultPaymentMethodModal({handleMDM_Close,makeDefaultModal}){
    const dispatch= useDispatch();
    const userToken = useSelector((store) => store.users.userToken);
    const makePaymentMethodDefaultLoading = useSelector((store) => store.settings.makePaymentMethodDefaultLoading);
    const makePaymentMethodDefaultStatus = useSelector((store) => store.settings.makePaymentMethodDefaultStatus);
    const details = makeDefaultModal;
    const {brand,exp_month,exp_year,last4,id} = details;
    //*401 - Invalid Token, user not logged in, proceed to log user out
    //*403 - Forbidden
    //*406 - No user found
    //408 - Transaction went unpaid
    //409 - Email already in use
    //410 - Too many API, cannot have more than 10 API

    //*503 - MongoDB Server Unavailable / bcrypt not encrypting password
    //*502 - 

    const [error,setError] = useState(false);

    const ERROR_MESSAGES = {
        401:"Error retrieving user credentials. You may be signed out",
        403:"Server Error. Retry later",
        406:'Server Error. Retry later',
        503:"Server Error. Retry later",
        502:`Oops! It looks like we were disconnected. Please try reloading the page.`
    }
    
    const handleMakeDefault = () => {
        if(!makePaymentMethodDefaultLoading){
            dispatch(makePaymentMethodDefault({userToken,paymentMethodID:id}));
        }
    }

    useEffect(()=>{
        const {success,attempt,error} = makePaymentMethodDefaultStatus;
        if(success && attempt){
            handleMDM_Close();
        }else{
            if(error !== 0){
                setError(true);
                setTimeout(()=>{
                    console.log('ribbit');
                    handleMDM_Close();
                },1500);
            }
        }
    },[makePaymentMethodDefaultStatus]);

    useEffect(()=>{
        return () =>{
            dispatch(refreshMakePaymentMethodDefaultStatus());
        }
    },[])

    const content = <div className="update-default-method-wrap" onClick={handleMDM_Close}>
                        <div className="inner-content" onClick={(e)=> e.stopPropagation()}>
                            {error ?
                                    <div className="Title62 error">
                                    {ERROR_MESSAGES[makePaymentMethodDefaultStatus.error]}
                                    </div>
                                : ''
                            }
                                <div className="Title62">
                                Are you sure you want to make default payment method?
                                </div>
                                <div className="details-row">
                                    <div className="Title63">
                                        {brand}
                                    </div>
                                    <div className="Title63">
                                        Exp {exp_month}/{exp_year}
                                    </div>
                                    <div className="Title63">
                                        ....{last4}
                                    </div>
                                </div>
                            <button onClick={handleMDM_Close} disabled={makePaymentMethodDefaultLoading || makePaymentMethodDefaultStatus.error !== 0}>Cancel</button>
                            <button onClick={handleMakeDefault} disabled={makePaymentMethodDefaultLoading || makePaymentMethodDefaultStatus.error !== 0}>Make default card</button>
                        </div>
                    </div>

    return ReactDOM.createPortal(content, document.getElementById('modal'));
}

function BillingHistory({}){
    const { billingDetails } = useSelector((store) => store.users.userDetails);
    const invoiceHistory = billingDetails !== "" ? billingDetails.invoiceHistory : [];
    console.log(billingDetails);
    console.log(invoiceHistory);

    const BillingHistoryComponent = () => {
        return(
            <div className="billing-history-list">
                {invoiceHistory.length === 0 ?
                    <div className="Title66">
                        No invoices to display
                    </div>
                    : 
                    <div className="header-row">
                        <div className="Title65">
                            INVOICE
                        </div>
                        <div className="Title67">
                            STATUS
                        </div>
                        <div className="Title68">
                            AMOUNT
                        </div>
                        <div className="Title69">
                            CREATED
                        </div>
                        <div className="Title70">

                        </div>
                    </div>
                }
                {invoiceHistory.map((method,index)=>(
                    <div className="individual-billing-row" key={index}>
                        <div className="Title75">
                            {method.number}
                        </div>
                        <div className="Title77">
                            {method.status}
                        </div>
                        <div className="Title78">
                            ${(method.amount_paid / 100).toFixed(2)}
                        </div>
                        <div className="Title79">
                            {new Date(method.paid_at * 1000).toLocaleString('en-US', {
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: true
                            })}
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    return(
        <div className="billing-history-wrap">
            <BillingHistoryComponent />
        </div>
    );
}


function BillingSetting(){
    const dispatch = useDispatch();
    const userToken = useSelector((store) => store.users.userToken);
    const checkingLocalStorageAttempt = useSelector((store) => store.users.checkingLocalStorageAttempt);

    const [renderInitPaymentModal,setRenderInitPaymentModal] = useState(false);

    const [optionSelected,setOptionSelected] = useState(0);
    //0 - Overview
    //1 - Methods
    //2 - History

    function handleSelection(num){
        setOptionSelected(num);
    }
    let bodyContent;
    if(optionSelected === 0){
        bodyContent = <BillingOverview 
                            setOptionSelected={setOptionSelected}
                            setRenderInitPaymentModal={setRenderInitPaymentModal}
                        />
    }
    if(optionSelected === 1){
        bodyContent = <PaymentMethods 
                            setRenderInitPaymentModal={setRenderInitPaymentModal}
                        />
    }
    if(optionSelected === 2){
        bodyContent = <BillingHistory />
    }

    useEffect(()=>{
        if(checkingLocalStorageAttempt){
            dispatch(refreshBillingSettings({userToken}));
        }
    },[]);

    useEffect(()=>{
        if(checkingLocalStorageAttempt){
            dispatch(refreshBillingSettings({userToken}));
        }
    },[checkingLocalStorageAttempt]);



    return(
        <React.Fragment>
            {renderInitPaymentModal ? 
                <InitialPaymentModal 
                    setRenderInitPaymentModal={setRenderInitPaymentModal}
                /> :
                ''
            }
            <div className="title">
                <div className="Title2">
                    Billing settings
                </div>
                <div className="billing-option-row">
                    <div className={`billing-option ${optionSelected === 0 ? 'selected' : 'not-selected'}`} id="overview" onClick={()=>{handleSelection(0)}}>
                        <div className="Title41">
                            Overview
                        </div>
                    </div>
                    <div className={`billing-option ${optionSelected === 1 ? 'selected' : 'not-selected'}`} id="methods" onClick={()=>{handleSelection(1)}}>
                        <div className="Title41">
                            Payment methods
                        </div>
                    </div>
                    <div className={`billing-option ${optionSelected === 2 ? 'selected' : 'not-selected'}`} id="history" onClick={()=>{handleSelection(2)}}>
                        <div className="Title41">
                                Billing history
                        </div>
                    </div>
                </div>
            </div>
            <div className="module-body">
                {bodyContent}
            </div>
        </React.Fragment>
    )
}


function InitialPaymentModal({setRenderInitPaymentModal}){
    const dispatch = useDispatch();
    const userToken = useSelector((store) => store.users.userToken);
    const initializePaymentLinkStatus = useSelector((store) => store.settings.initializePaymentLinkStatus);
    const initializePaymentLinkLoading = useSelector((store) => store.settings.initializePaymentLinkLoading);

    const [renderError,setRenderError] = useState(0);

    useEffect(()=>{
        dispatch(initializePaymentLink_InitialSubscription({userToken}));
    },[])

    const ERROR_MESSAGES = {
        401:"Error retrieving user credentials. You may be signed out",
        403:"Server Error. Retry later",
        503:"Server Error. Retry later",
        502:`Oops! It looks like we’re having some technical difficulties. Please try reloading the page in a few moments`
    }

    useEffect(()=>{
        if(!initializePaymentLinkLoading){
            const {success,attempt,paymentLink,error} = initializePaymentLinkStatus;
            if(success && attempt){
                window.location.href = paymentLink;
            }
            if(!success && attempt){
                setRenderError(error);
                dispatch(refreshInitializeSubscriptionLink());
                setTimeout(()=>{setRenderInitPaymentModal(false)},2000);
            }
        }
    },[initializePaymentLinkLoading]);

    let content;
    content = 
    <React.Fragment>
        <div className="billing-modal-wrap">
            <div className="billing-modal-content">
                <div className="title-row">
                    <div className="Title48">
                        Navigating to
                    </div>
                    <div className="striple-logo-wrap">
                        <img src="/Stripe-logo.png" />
                    </div>
                </div>

                <div className="loading-circle">
                    
                </div>
            </div>
        </div>
    </React.Fragment>

    if(renderError !== 0){
        content = 
        <React.Fragment>
            <div className="billing-modal-wrap">
                <div className="billing-modal-content">
                    <div className="Title48 error">
                            {ERROR_MESSAGES[renderError]}
                    </div>
                </div>
            </div>
        </React.Fragment>
    }

    return ReactDOM.createPortal(content, document.getElementById('modal'));
}




function ProfileSetting(){
    const userDetails = useSelector((store) => store.users.userDetails);
    const {name,number,userName} = userDetails;

    const [formState, inputHandler, setFormData] = useForm(
        {
          name: {
            value: '',
            isValid: false
          },
          email: {
            value: '',
            isValid:true
          },
        //   number: {
        //     value: '',
        //     isValid:true
        //   }
        },
        false
      );
    
    return(
        <React.Fragment>
            <div className="title">
                <div className="Title2">
                    Profile
                </div>
            </div>
            <div className="module-body">
                <div className="Title22">
                    Name
                </div>
                <div className="Title23">
                    The name associated with this account
                </div>
                <Input
                    element="input"
                    id="name"
                    type="text"
                    label=""
                    validators={[VALIDATOR_MINLENGTH(1),VALIDATOR_MAXLENGTH(40)]}
                    errorText=""
                    onInput={inputHandler}
                    mismatchText=""
                    initialValid={false}
                    initialValue={name}
                />

                <div className="Title22">
                    Email address
                </div>
                <div className="Title23">
                    The email address associated with this account
                </div>
                <Input
                    element="input"
                    id="email"
                    type="email"
                    label=""
                    validators={[]}
                    errorText=""
                    onInput={inputHandler}
                    mismatchText=""
                    initialValid={true}
                    initialValue={userName}
                    readonly={true}
                />

                {/* <div className="Title22">
                    Phone number
                </div>
                <div className="Title23">
                    The phone number associated with this account
                </div>
                <Input
                    element="input"
                    id="number"
                    type="number"
                    label=""
                    validators={[]}
                    errorText=""
                    onInput={inputHandler}
                    mismatchText=""
                    initialValid={true}
                    initialValue={number}
                    readonly={true}
                /> */}

                <button id="profile-save">Save</button>
            </div>
        </React.Fragment>
    )
}