import React, {useEffect} from 'react';

const TermsAndConditions = () => {

  useEffect(() => {
    // Temporary simplification
    return () => {};  // Empty cleanup function
  }, []);

  return (
    <div className="terms-container">
            Terms and Conditions
    </div>
  );
};

export default TermsAndConditions;
