import React, {useState,useCallback,useEffect,useRef} from 'react';
import axios, {isCancel, AxiosError} from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as d3 from 'd3';

import { refreshWallet } from '../../../shared/store/slices/walletSlice';

import './Wallet.css'

function Wallet(){
    const dispatch = useDispatch();
    const userToken = useSelector((store) => store.users.userToken);
    const checkingLocalStorageAttempt = useSelector((store) => store.users.checkingLocalStorageAttempt);
    const walletDetails = useSelector((store) => store.wallet.walletDetails);
    const{
        walletLoaded,
        walletInit,
        walletDetailsBook,
        tier,
        pricePer10000,
        blockcahin_height,
    } = walletDetails;

    useEffect(()=>{
        if(checkingLocalStorageAttempt){
            dispatch(refreshWallet({userToken}));
        }
    },[]);

    useEffect(()=>{
        if(checkingLocalStorageAttempt){
            dispatch(refreshWallet({userToken}));
        }
    },[checkingLocalStorageAttempt]);

    return(
        <div className="WALLET-MODULE">
            <div className="title">
                <div className="Title2">
                    Wallet
                </div>
            </div>
            <div className="module-body">
                <div className="top-half">
                    <div className="left-panel">
                        <MAIN_WALLET 
                            walletDetails={walletDetails}
                        />
                    </div>
                    <div className="right-panel">
                    <TRANSACTION_HISTOGRAM />
                    <GEOGRAPHY />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Wallet;












function TRANSACTION_HISTOGRAM(){
    return(
        <div className="TRANSACTION_HISTOGRAM">
                
        </div>
        );
} 

function GEOGRAPHY(){
    return(
        <div className="GEOGRAPHY">
            
        </div>
        );
}

function MAIN_WALLET({
    walletDetails
}){

    const{
        walletLoaded,
        walletInit,
        walletDetailsBook,
        tier,
        pricePer10000,
        blockcahin_height,
        fees
    } = walletDetails;


    const [selectedAddressDetails,setSelectedAddressDetails] = useState();
    const [locked,setLocked] = useState(false);
    const [clearAllLocks,setClearAllLocks] = useState(false);
    const hoverTimeoutRef = useRef(null);

    const handleMouseEnter = (addressDetails) => {
        if (hoverTimeoutRef.current) {
            clearTimeout(hoverTimeoutRef.current);
            hoverTimeoutRef.current = null;
          }
          if(!locked){
            setSelectedAddressDetails(addressDetails);
          }
        
    };

    const handleMouseLeave = () => {
            hoverTimeoutRef.current = setTimeout(() => {
                if(!locked){
                    setSelectedAddressDetails(null);
                    hoverTimeoutRef.current = null;
                }else{

                }

            }, 2000);
    };

    const onAddressClick = (details) => {
        console.log(details);
        setLocked(true);
        setSelectedAddressDetails(details);
    }

    const onAddressSecondClick = () => {
        setLocked(false);
    }

    const clearOuterLock = () => {
        setLocked(false);
        setSelectedAddressDetails(null);
        setClearAllLocks(true);
    }




    useEffect(()=>{
        if(clearAllLocks){
            setTimeout(()=>{
                setClearAllLocks(false);
            },25);
        }
    },[clearAllLocks]);


    let address_map = [];
    let address_rows = [];
    if(tier === 0){
        if(walletDetailsBook.length === 0){
            //If there are no wallet details for some reason
            for (let i = 1; i < 31; i++) {
                address_map.push(
                    <div className="address-wrap" id={`tier${tier}_${i}`} key={i}>
                    {/* Content of each address */}
                    unassigned
                    </div>
                );
                }
            
                // Group the address divs into rows of 5
                for (let j = 0; j < address_map.length; j += 5) {
                    address_rows.push(
                    <div className="address-row" key={j}>
                    {address_map.slice(j, j + 5)}
                    </div>
                );
            }
        }else{
            let i = 1;
            for(const wallet of walletDetailsBook){
                address_map.push(
                    <AddressWrap
                        wallet={wallet}
                        tier={tier}
                        key={i}
                        handleMouseEnter={handleMouseEnter}
                        handleMouseLeave={handleMouseLeave}
                        onAddressClick={onAddressClick}
                        onAddressSecondClick={onAddressSecondClick}
                        clearOuterLock={clearOuterLock}
                        clearAllLocks={clearAllLocks}
                        locked={locked}
                        setSelectedAddressDetails={setSelectedAddressDetails}
                    />
                )
                i++;
            }
            for (let j = 0; j < address_map.length; j += 5) {
                address_rows.push(
                <div className="address-row" key={j}>
                {address_map.slice(j, j + 5)}
                </div>
            );
        }
    }
}





    return(
        <div className="MAIN_WALLET">
            <div className="side-wallet-details">
                <div className="current-amounts">
                    <div className="Title10">
                        CURRENT
                    </div>
                    <div className="current-inner-body-wrap">
                        <div className="Title11">
                            $0
                        </div>
                        <div className="spacer">

                        </div>
                        <div className="Title12">
                            0.00000000
                        </div>
                        <div className="Title13">
                            BTC
                        </div>
                    </div>

                </div>
                <div className="transactions-wrap">
                        <div className="available">
                            <div className="Title14">
                            AVAILABLE
                            </div>
                            <div className="Title15">
                                30
                            </div>
                        </div>
                        <div className="pending">
                            <div className="Title14">
                                PENDING
                            </div>
                            <div className="Title15">
                                0
                            </div>
                        </div>
                </div>
                <div className="wallet-settings">
                    <div className="wallet-graphic">
                        SETTINGS
                    </div>
                </div>

            </div>
            <div className="wallet-overview">
                <div className="graphical-display">
                    {address_rows}
                </div>
                <div className="stats">
                    <STATS_TAB 
                        walletDetails={walletDetails}
                        selectedAddressDetails={selectedAddressDetails}
                    />
                </div>
            </div>
        </div>
        );
}



function AddressWrap({wallet,tier,key,locked,
    handleMouseEnter,
    handleMouseLeave,
    onAddressClick,
    onAddressSecondClick,
    clearOuterLock,
    clearAllLocks,
    setSelectedAddressDetails
}){

    const [thisLocked,setThisLocked] = useState(false);

    const onInternalClick = (wallet) => {
        if (!thisLocked && !locked) {
            setThisLocked(true);
            onAddressClick(wallet);
        }else{
            if(locked && !thisLocked){
                clearOuterLock();
                setSelectedAddressDetails(wallet);
            }
        }
    }

    const onUnlockClick = () => {
        console.log('CLICK');
        setThisLocked(false);
        onAddressSecondClick();
    }

    useEffect(()=>{
        if(clearAllLocks){
            setThisLocked(false);
        }
    },[clearAllLocks]);

    return(
        <div className={`address-wrap ${wallet.in_use ? 'in-use' : ''} ${locked && thisLocked ? 'locked' : ''}`} id={`tier${tier}_${key}`} key={key}
        onMouseEnter={()=> handleMouseEnter(wallet)}
        onMouseLeave={handleMouseLeave}
        onClick={()=> onInternalClick(wallet)}
    >
        {locked && thisLocked ? 
                <div className="unlock-x" onClick={onUnlockClick}>
                        X
                </div>  
                :
                ''
       }
    </div>
    );
}



function STATS_TAB({
    walletDetails,
    selectedAddressDetails
}){
    const{
        walletLoaded,
        walletInit,
        walletDetailsBook,
        tier,
        pricePer10000,
        blockchain_height,
        fees,
        wallet_stats,
        historicalWalletDetails
    } = walletDetails;

    const {w_total_uses,w_usd_volume,w_btc_volume} = wallet_stats;


    const convertToOneBTC = (pricePer10000) => {
        return parseFloat((10000 / pricePer10000).toFixed(2));
    }
    const pricePerBTC = convertToOneBTC(pricePer10000);
    console.log(blockchain_height);
    const {height,time} = blockchain_height;

const convertTimeFormat = (date) => {
    if (!(date instanceof Date)) {
        date = new Date(date); // Convert integer to Date object
    }

    function getDayOfMonth(date) {
        const day = date.getDate();
        return day;
    }

    const options = { timeZone: 'America/New_York', timeZoneName: 'short' };
    const formatter = new Intl.DateTimeFormat('en-US', options);
    const month = date.toLocaleString('en-US', { month: 'long' });
    const day = getDayOfMonth(date);
    const time = date.toLocaleTimeString('en-US', { timeZone: 'America/New_York' });

    const formattedDate = `${month} ${day} ${time} EST`;
    return formattedDate;
}
    const heightConfirmationTime = convertTimeFormat(time);

const { name: rawAddressNumber, current_balance } = selectedAddressDetails || {};
const addressNumber = rawAddressNumber ? parseInt(rawAddressNumber.match(/\d+/)[0], 10) : '';

    let statsTab;
    if(!walletInit && !walletLoaded){
        statsTab = <React.Fragment>
                        <div className="Title16">
                            WALLET NOT INITIALIZED
                        </div>

                        <div className="Title17">
                            In order to initialize your wallet, make first payment and generate a private key.
                        </div>
                    </React.Fragment>
    }
    if(walletInit && !walletLoaded){
        statsTab = <React.Fragment>
                    <div className="Title16">
                        WALLET INITIALIZING
                    </div>

                    <div className="Title17">
                        Your wallet is loading...
                    </div>
                </React.Fragment>
    }
    if(walletInit && walletLoaded){
        //CREATE STATS TAB WITH ADVANCED STATS
        statsTab = <React.Fragment>
            
                <div className="overall-left">
                        <div className="top">
                            <div className="network-fees-title">
                                NETWORK FEES
                            </div>
                        <NetworkFeeCircle 
                            fees={fees}
                        />
                            <div className="live-price-wedge">

                            </div>
                            <div className="live-price-details-wrap">
                                <div className="live-callout">
                                    LIVE
                                </div>
                                <div className="price-callout">
                                    ${pricePerBTC}
                                </div>
                            </div>
                        </div>
                        <div className="bottom">
                            <img src="/img/color_cubes_block_25px.png" />
                            <div className="text-wrap">
                                <div className="top-text">
                                        {height}
                                </div>
                                <div className="small-text">
                                        {heightConfirmationTime}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="overall-right">
                        <div className="top-banner">
                            <div className="total-title">
                                TOTAL
                            </div>
                            <div className="totals-wrap">
                                <div className="usd">
                                    ${w_usd_volume}
                                </div>
                                <div className="wedge">

                                </div>
                                <div className="btc">
                                    {w_btc_volume}
                                </div>
                            </div>
                        </div>
                    <HistoricalGraph />


                    </div>                    


                </React.Fragment>
    }
    if(selectedAddressDetails){
        statsTab = 
                <div className="moreAddressDetails">
                        <div className="Title80">
                            No. {addressNumber}
                        </div>
                    <div className="Title17">
                        <div className="inset-crypto">
                            {parseInt(selectedAddressDetails.current_balance) === 0 ? '0.00000000' : selectedAddressDetails.current_balance}
                        </div>
                        <div className="raised-usd">

                        </div>
                    </div>
                </div>
    }

    return statsTab;

}



const NetworkFeeCircle = ({fees}) => {
console.log(fees);
    return(
        <div className="network-fee-graphic">
            <svg class="round" viewbox="0 0 50 50" width="60" height="60" data-percent="9">
                <circle className="background-ring" cx="36" cy="24" r="18" />  
                <circle className="indicator-ring" cx="36" cy="24" r="18" />  
            </svg>
        </div>
    );
}


const HistoricalGraph = () => {
    const containerRef = useRef(null);
    const walletDetails = useSelector((store) => store.wallet.walletDetails);
    const {historicalWalletDetails} = walletDetails;

    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const [dataSelected,setDataSelected] = useState(2);

    const dataSets = {
        1:{name:"daily"},
        2:{name:"weekly"},
        3:{name:"monthly"},
        4:{name:"quarterly"},
        5:{name:"yearly"},
        6:{name:"ytd"}
    }

    const clickToSelect = (num) => {
        setDataSelected(num);
    }

    const data_to_pass = historicalWalletDetails[dataSets[dataSelected].name];
    
    useEffect(() => {
        const updateDimensions = () => {
          if (containerRef.current) {
            setDimensions({
              width: containerRef.current.offsetWidth,
              height: containerRef.current.offsetHeight
            });
          }
        };
    
        updateDimensions();
    
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
      }, []);
    


    return(
        <div className="historical-graph-wrap" ref={containerRef}>
        <div className="graph">
                <div className="selection-header">
                    <div className={`selection ${dataSelected === 1 ? 'x' : ''}`}
                        onClick={()=>clickToSelect(1)}
                    >
                    1D
                    </div>
                    <div className={`selection ${dataSelected === 2 ? 'x' : ''}`}
                        onClick={()=>clickToSelect(2)}
                    >
                    1W
                    </div>
                    <div className={`selection ${dataSelected === 3 ? 'x' : ''}`}
                        onClick={()=>clickToSelect(3)}
                    >
                    1M
                    </div>
                    <div className={`selection ${dataSelected === 4 ? 'x' : ''}`}
                        onClick={()=>clickToSelect(4)}
                    >
                    1Q
                    </div>
                    <div className={`selection ${dataSelected === 5 ? 'x' : ''}`}
                        onClick={()=>clickToSelect(5)}
                    >
                    1Y
                    </div>
                    <div className={`selection ${dataSelected === 6 ? 'x' : ''}`}  
                        onClick={()=>clickToSelect(6)}>
                    YTD
                    </div>
                </div>
                <div className="d3-graph-plot">
                    <D3_GraphComponent 
                        data={data_to_pass}
                        type={dataSets[dataSelected].name}
                        dimensions={dimensions}
                    />
                </div>
        </div>
        {/* <div className="side-panel">

        </div> */}
        </div>
    );
}


const D3_GraphComponent = ({data,type,dimensions}) => {
    const ref = useRef();

    const containerWidth = dimensions.width;
    const containerHeight = dimensions.height;
    
    console.log(data);
    console.log(type);

    useEffect(() => {
        const svg = d3.select(ref.current);
        svg.selectAll('*').remove(); // Clear previous content
    
        // Set up dimensions
        const margin = { top: 2, right: 20, bottom: 15, left: 2 };
        const width = containerWidth - margin.left - margin.right;
        const height = containerHeight - margin.top - margin.bottom - 20;
    
        const g = svg
          .append('g')
          .attr('transform', `translate(${margin.left},${margin.top})`);
    
        // Parse the date / time
        const parseDate = d3.timeParse('%Y-%m-%d');
        const parseYearWeek = d3.timeParse('%Y-%U');
    
        // Define the scales
        const x = d3.scaleTime().range([0, width]);
        const y = d3.scaleLinear().range([height, 0]);
    
        let parsedData;
    
        if (type === 'daily') {
          parsedData = data.map(d => ({ date: parseDate(d.date), totalAmount: d.totalAmount }));
        } else if (type === 'yearly') {
          parsedData = data.map(d => ({ date: parseYearWeek(d.date), totalAmount: d.totalAmount }));
        } else {
          parsedData = data.map(d => ({ date: parseDate(d.date), totalAmount: d.totalAmount }));
        }
    
        // Define the axes
        const xAxis = d3.axisBottom(x);
        const yAxis = d3.axisRight(y);
    
        // Scale the range of the data
        const now = new Date();
        let domainStart;
    
        switch (type) {
          case 'daily':
            domainStart = new Date(now);
            domainStart.setHours(now.getHours() - 24);
            break;
          case 'weekly':
            domainStart = new Date(now);
            domainStart.setDate(now.getDate() - 7);
            break;
          case 'monthly':
            domainStart = new Date(now);
            domainStart.setDate(now.getDate() - 30);
            break;
          case 'quarterly':
            domainStart = new Date(now);
            domainStart.setDate(now.getDate() - 90);
            break;
          case 'yearly':
            domainStart = new Date(now);
            domainStart.setDate(now.getDate() - 365);
            break;
          case 'ytd':
            domainStart = new Date(now.getFullYear(), 0, 1);
            break;
          default:
            domainStart = d3.min(parsedData, d => d.date);
            break;
        }
    
        x.domain([domainStart, now]);
        y.domain([0, d3.max(parsedData, d => d.totalAmount)]);
    
        // Add the x-axis
        g.append('g')
          .attr('transform', `translate(0,${height})`)
          .call(xAxis);
    
        // Add the y-axis
        g.append('g')
            .attr('transform', `translate(${width},0)`)
            .call(yAxis);
    
        // Add the line
        const line = d3
          .line()
          .x(d => x(d.date))
          .y(d => y(d.totalAmount));
    
        g.append('path')
          .datum(parsedData)
          .attr('fill', 'none')
          .attr('stroke', 'steelblue')
          .attr('stroke-width', 1.5)
          .attr('d', line);
      }, [data, type, dimensions]);

      return <svg ref={ref} width={containerWidth} height={containerHeight - 20} />

}