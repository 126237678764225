import React, {useState,useCallback,useEffect} from 'react';
import axios, {isCancel, AxiosError} from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { GoogleLogin } from '@react-oauth/google';

import { fetchRegistrationKey,sendRegistrationDetails } from '../../shared/store/slices/userSlice';
import { useForm } from '../../shared/hooks/form-hook';

import { googleBackendRegister } from '../../shared/store/slices/googleSlice';
import { refreshUserRegistrationAttempt } from '../../shared/store/slices/userSlice';
import { refreshGoogleRegistrationAttempt } from '../../shared/store/slices/googleSlice';

import {
    VALIDATOR_EMAIL,
    VALIDATOR_REQUIRE,
    VALIDATOR_PASSWORD
  } from '../../shared/util/validators';

  import Input from '../../shared/components/FormElements/Input';
  import NewInput from '../../shared/components/FormElements/NewInput';

import './Register.css';

function Register() {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const { 
      registrationKeyLoading,
      registrationKey,
      registrationKeyError,
      registrationKeyErrorText,
      userRegistrationLoading,
      userRegistrationError,
      userRegistrationErrorText,
      userRegisterSuccess,
      userDetailsSet,
      userToken,
    } = useSelector((store)=>store.users);


  const [showRegistrationKeyError,setShowRegistrationKeyError] = useState(false);


    //Get Registration Token
    useEffect(()=>{
      dispatch(fetchRegistrationKey());
          return () => {
              dispatch(refreshUserRegistrationAttempt());
              dispatch(refreshGoogleRegistrationAttempt());
          };  // Empty cleanup function
    },[]);

    useEffect(()=>{
      if(userRegisterSuccess && userDetailsSet){
        navToConsole();
      }
    },[userRegisterSuccess,userDetailsSet])



    const [formState, inputHandler, setFormData] = useForm(
        {
          email: {
            value: '',
            isValid: false
          },
          password: {
            value: '',
            isValid: false
          },
        },
        false
      );

      function navToConsole() {
        return new Promise((resolve, reject) => {
          navigate(`/console`, {
            onComplete: resolve,
            onError: reject,
          });
        });
      }

      async function handleFormSubmit(e){
        e.preventDefault();
        if(registrationKeyLoading){
          return;
        }
        if(registrationKeyError){
          setShowRegistrationKeyError(true);
          setTimeout(()=>{
            setShowRegistrationKeyError(false);
          },5000);
        }


        if(formState.isValid && registrationKey){
            const {email,password} = formState.inputs;
            // AXIOS SEND REQUEST

            dispatch(sendRegistrationDetails({
              email:email,
              password:password,
              token:registrationKey,
            }));
        }
            
      }


      useEffect(()=>{
        if(userToken){
            navToConsole();
        }
      },[userToken]);


        return(
            <div className="register-wrap">
                {registrationKeyLoading ?
                    <h3>LOADING</h3> 
                    :
            <div className="create-account-wrap">
                <form id="registerForm">
                    <h2>Create your account</h2>
                    <div className="input-group">
                              <NewInput
                                element="input"
                                id="email"
                                type="text"
                                label="Email address"
                                validators={[VALIDATOR_EMAIL()]}
                                errorText="Please enter a valid email."
                                onInput={inputHandler}
                                mismatchText="Email is already in use. Please use another email."
                              />
                            {/* <Input
                                element="input"
                                id="password"
                                type="password"
                                label="Password"
                                validators={[VALIDATOR_PASSWORD()]}
                                errorText="Password must contain a minimum of 8 characters- with atleast 1 lowercase letter, 1 uppercase letter, 1 number, and 1 special character."
                                onInput={inputHandler}
                            /> */}
                    </div>
                    <button onClick={handleFormSubmit}>Continue</button>
                </form>
                <div className="Title31">
                  Already have an account? Log In
                </div>
                <div className="or-row">
                          <div className="or-line">

                          </div>
                    <div className="Title32">
                            OR
                    </div>
                    <div className="or-line">
                            
                            </div>
                </div>
                  
                <GoogleWrap />


            </div>
            }
            </div>
        );


    {/* //   return (
    //     <div className="container">
    //       <h2 className="header">Create API KEY</h2>
    //       <h3>Username: {userName}</h3>
    //       {error && <p className="error">{error}</p>}
    //       <button className="button" onClick={handleRegister}>Register</button>
    //       {password && <div className="passwordBox">Your password: {password}</div>}
    //     </div>
    //   ); */}
}

export default Register;



function GoogleWrap(){
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const googleBackendLoading = useSelector((store)=> store.google.googleBackendRegisterLoading);
  const {registrationKey} = useSelector((store)=>store.users);
  const googleBackendRegisterResponse = useSelector((store) => store.google.googleBackendRegisterResponse);

  function navToConsole() {
    return new Promise((resolve, reject) => {
      navigate(`/console`, {
        onComplete: resolve,
        onError: reject,
      });
    });
  }

  useEffect(()=>{
    const {status,attempt} = googleBackendRegisterResponse;
      if(status){
        navToConsole();
      }
  },[googleBackendRegisterResponse]);




  function LoginSuccess(credentialResponse){
    console.log(credentialResponse);
    const { credential, clientId } = credentialResponse;
    dispatch(googleBackendRegister({registrationKey,clientId,credential}));
  };

  function LoginError(){
    console.log("Login Failed")
  }

  return(
    <div className="google-wrap">
      {googleBackendLoading ? 'LOADING' :
            <GoogleLogin
            onSuccess={LoginSuccess}
            onError={LoginError}
            size="large"
          />
      }
    </div>
  );
}