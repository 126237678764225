import React, {useState,useCallback,useEffect} from 'react';
import axios, {isCancel, AxiosError} from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { GoogleLogin } from '@react-oauth/google';

import { postCheckoutBilling, refreshUserDetails  } from '../../shared/store/slices/userSlice';
import { setNavigateToBilling } from '../../shared/store/slices/settingsSlice';

import './PostCheckout.css';


    //*401 - Invalid Token, user not logged in, proceed to log user out
    //*403 - Forbidden
    //*406 - No user found
    //*408 - Transaction went unpaid
    //409 - Email already in use
    //410 - Too many API, cannot have more than 10 API

    //*503 - MongoDB Server Unavailable / bcrypt not encrypting password
    //*502 - Network Connection. Can't locate server. Oops! It looks like we’re having some technical difficulties. Please try reloading the page in a few moments



function PostCheckout(){
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const { billing_param } = useParams();
    const checkingLocalStorageAttempt = useSelector((store) => store.users.checkingLocalStorageAttempt);
    const postCheckoutBillingLoading = useSelector((store) => store.users.postCheckoutBillingLoading);
    const postCheckoutBillingStatus = useSelector((store) => store.users.postCheckoutBillingStatus);
    const refreshUserDetailsStatus = useSelector((store) => store.users.refreshUserDetailsStatus);

    const userToken = useSelector((store) => store.users.userToken);
    const [comment,setComment] = useState(0);
    const [errorMessage,setErrorMessage] = useState(0);

    useEffect(()=>{
        if(checkingLocalStorageAttempt){
            if(userToken){
                dispatch(postCheckoutBilling({token:userToken,checkout_session:billing_param}));
            }else{
                // Check localStorage for token - NO TOKEN REDIRECT HOME
                console.log("Redirecting Home");
                setTimeout(()=>{
                    navToHome();
                },1000);
            }
        }
    },[checkingLocalStorageAttempt]);
    // If token check validity of token - NO VALID TOKEN - LOG OUT - REDIRECT HOME

    // If toke active - Check user billing status. If all good redirect to console/settings/billing
                                                // If no billing status - Await 5 seconds - recheck
                                                // If no billing status - Check Stripe system for checkout_sessionID
                                                // If COMPLETE - RECOMPLETE BitKiosk backend
                                                //If Incomplete - Set Payment Error - Redirect to Console with Error Message

    const ERROR_MESSAGES = {
        401:"Error retrieving user credentials. You may be signed out",
        403:"Server Error. We are working to resolve",
        406:"Thank you. Taking you to console",
        408:"Error with transaction. Subscription remains unpaid",
        503:"Server Error. We are working to resolve",
        502:`Oops! It looks like we were disconnected. Please try reloading the page in a few moments`
    }


    useEffect(()=>{
        if(!postCheckoutBillingLoading){
            const {success,attempt,error} = postCheckoutBillingStatus;
            if(success && attempt){
                //Thank You For Payment - Payment Received - Redirecting to Console - refreshUserDetails
                setComment(1);
                dispatch(setNavigateToBilling());
                dispatch(refreshUserDetails({userToken}));
            }
            if(!success && attempt){
                // Error with Payment
                //Error with token
                setComment(2);
                setErrorMessage(error);
                setTimeout(()=>{
                    navToConsole();
                },2000);
            }
        }
    },[postCheckoutBillingLoading]);

    function navToConsole() {
        return new Promise((resolve, reject) => {
            navigate(`/console`, {
            onComplete: resolve,
            onError: reject,
            });
        });
    }

    function navToHome() {
        return new Promise((resolve, reject) => {
            navigate(`/`, {
            onComplete: resolve,
            onError: reject,
            });
        });
    }

    useEffect(()=>{
        const {success,attempt,error} = refreshUserDetailsStatus;
        if(attempt){
            setTimeout(()=>{
                navToConsole();
            },2600);
        }
    },[refreshUserDetailsStatus]);


    const comment1 = <React.Fragment>
                        <div className="Title50">
                            PAYMENT RECEIVED
                        </div>
                        <div className="Title51">
                            Taking you to console
                        </div>
                    </React.Fragment>;

    const errorComment = <React.Fragment>
                            <div className="Title50">
                                {ERROR_MESSAGES[errorMessage]}
                            </div>
                        </React.Fragment>
 
    let thisComment;
    if(comment === 0){
        thisComment = '';
    }
    if(comment === 1){
        thisComment = comment1;
    }
    if(comment === 2){
        thisComment = errorComment;
    }




    //Send refreshed token before redirect

    //On Login - If Payment Attempted but Missing Info - Reverify with Stripe to Confirm
    //Check session status - 
    //If session was already completed, reload user console or reject with error
    // If no checkoutsession valid - reload user page or reject with error
    //If userToken - Show Success Message and Return to Console - Load Settings/Billing Page
    //If userToken - Return uid from session and Return to Console - Load Settings/Billing Page
    //Mark checkout session as completed
    return(
        <div className="Post-Checkout-Loading-Wrap">
                <div className="comment-block">
                    {thisComment}
                </div>
            <div class="loader">
                <div class="loader-square"></div>
                <div class="loader-square"></div>
                <div class="loader-square"></div>
                <div class="loader-square"></div>
                <div class="loader-square"></div>
                <div class="loader-square"></div>
                <div class="loader-square"></div>
            </div>
        </div>
    );
}

export default PostCheckout;