import React, {useState,useCallback,useEffect} from 'react';
import axios, {isCancel, AxiosError} from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { GoogleLogin } from '@react-oauth/google';

import { google_Login } from '../../shared/store/slices/googleSlice'; 

import './Login.css'; // Import the stylesheet

function Login(){
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const google_LoginStatus = useSelector((store) => store.google.google_LoginStatus);
    const userDetailsSet = useSelector((store) => store.users.userDetailsSet);
    const userToken = useSelector((store) => store.users.userToken);

    function LoginSuccess(credentialResponse){
        console.log(credentialResponse);
        const { credential, clientId } = credentialResponse;
        dispatch(google_Login({clientId,credential}));
      };

      function LoginError(){
        console.log("Login failed");
      }

      function navToConsole() {
        return new Promise((resolve, reject) => {
          navigate(`/console`, {
            onComplete: resolve,
            onError: reject,
          });
        });
      }

      useEffect(()=>{
        const {success,attempt} = google_LoginStatus;
        if(success && attempt && userDetailsSet){
            console.log(success,attempt,userDetailsSet);
            console.log(userToken);
            navToConsole();
        }
      },[google_LoginStatus,userDetailsSet])


      useEffect(()=>{
        if(userToken){
            navToConsole();
        }
      },[userToken]);


        return (
            <div className="login-wrap">
                <div className="google-login-wrap">
                    <GoogleLogin
                        onSuccess={LoginSuccess}
                        onError={LoginError}
                        size="large"
                    />
                </div>
            </div>

        )
}

export default Login;

