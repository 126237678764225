import { configureStore } from '@reduxjs/toolkit';
import userReducer from './slices/userSlice';
import apiReducer from './slices/apiSlice';
import settingsReducer from './slices/settingsSlice';
import walletReducer from './slices/walletSlice';
import googleReducer from './slices/googleSlice';

export default configureStore({
    reducer:{
        users:userReducer,
        api:apiReducer,
        settings:settingsReducer,
        wallet:walletReducer,
        google:googleReducer,
    }
})

export const internal_api_href = 'https://bitkiosk.net/api/';