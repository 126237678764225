import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';

import './Docs.css';

const DocPage = () => {

    const RequestPaymentStatusCodes = [
        {status:200, short:"OK", long:"Everything worked as expected"},
        {status:400, short:"Bad Request", long:"Missing or invalid parameter"},
        // {status:400, short:"Bad Request", long:"Missing parameter: Amount is required"},
        // {status:400, short:"Bad Request", long:"Invalid parameter: Amount must be a valid number"},
        // {status:400, short:"Bad Request", long:"Pre-Emptive Request: Wallet Not Loaded Yet. Try back soon"},
        {status:401, short:"Unauthorized", long:"Invalid API Key"},
        {status:402, short:"Request Failed", long:"No Available Addresses for Usage"},
        {status:403, short:"Unauthorized", long:"Invalid BitKiosk Identification"},
        {status:404, short:"Not Found", long:"Unable to locate BitKiosk Public ID"},
        {status:405, short:"Not Found", long:"Unable to locate APIs for this Organization. Ensure API Keys are Active"},
        // {status:500, short:"Server Error", long:"Payment Request Failed (Unable to update BOOK or PAYMENTS)"},
        {status:500, short:"Server Error", long:"Bitkiosk Server Error (Rare)"},
        // {status:500, short:"Server Error", long:"Bitkiosk Server Error (Error decoding API Details)"},
        {status:502, short:"Server Error", long:"Payment Request Failed"}
    ];

    const requestPaymentStatusElements = RequestPaymentStatusCodes.map((status, index) => (
        <div key={index} className="status-row">
                    <div className="status">
                        {status.status}
                    </div>
                    <div className="short">
                        {status.short}
                    </div>
                    <div className="long">
                        {status.long}
                    </div>
        </div>
    ));

    const curlCommand_RequestPayment = 
` curl -X POST "http://134.209.208.57:3001/request-payment" \\
    -H "Content-Type: application/json" \\
    -H "x-bitkiosk-public-key: L6szix6HpiO75JOmQCnBxIeXEel9kH" \\
    -d '{"bitkiosk_public":"99c40213-e853-42cf-9adc-fd507d8e1cf9", 
        "private_key":"jpgGZ64tRyRvljFnPfNFI1Z2rTOrtx", 
        "payment_details":{"amount":"500"}}'`;



    const paymentDetailParams = [
        {title:"amount",required:true,memo:"Integer in USD cents"},
        {title:"customer",required:true,memo:"Customer ID provided by you"},
        {title:"order_id",required:true,memo:"Order ID provided by you"},
        {title:"id",required:true,memo:"Payment ID Created by BitKiosk"},
        {title:"metadata",required:true,memo:"Any key value pairs associated with this payment"},
        {title:"payment_information",required:true,memo:"Detailed information regarding specific items of the order",subParams:[
            {title:"description",required:false,memo:"Description of the order"},
            {title:"items",required:false,memo:"Itemized details",subParams:[
                {title:"name",required:false,memo:"Name of the item"},
                {title:"qty",required:false,memo:"Quantity of this item"},
                {title:"description",required:false,memo:"Description of the item"},
                {title:"img_url",required:false,memo:"Url of image link for display to customer"},
                {title:"item_amount",required:false,memo:"Amount of this item in cents USD"},
            ]},
        ]},
        {title:"status",required:false,memo:"Status of this order. Monitored by BitKiosk"},
    ]

    const PaymentDetailParams_Element = paymentDetailParams.map((detail,index)=>(

        <div className="rule">
            <div className="rule-title">
                {detail.title}
            </div>
            <div className="rule-memo">
                {detail.memo}
            </div>
        </div>

    ))









// Then render it within <pre> tags to preserve line breaks



    return(
        <div className="doc-wrap">
            <div className="console-header">
                <div className="logo-header">
                    <div className="Title1">
                        BitKiosk Documentation
                    </div>
                </div>
                <div className="options">
                        <div className="Text1">
                            Help
                        </div>
                    </div>
            </div>
        <div className="content-wrap">
            {/* <div className="title">
                <div className="Title70">
                    Documentation
                </div>
            </div> */}
            <div className="api-doc-panel">
                <div className="left">
                    <div className="Title71">
                        API Reference
                    </div>
                    <div className="paragraph">
                        The BitKiosk API is organized around REST. Our API has predictable resource-oriented URLs, accepts form-encoded request bodies, returns JSON-encoded responses, and uses standard HTTP response codes. 
                    </div>
                </div>
                <div className="right">
                        <div className="Title72">
                            Just getting started?
                        </div>
                        <div className="paragraph">
                        BitKiosk prioritizes simplicity, enabling effortless management of a private Bitcoin wallet, even for those with no coding experience. Explore user-friendly options tailored for beginners
                    </div>
                </div>
            </div>
            <div className="api-doc-panel">
                <div className="left">
                    <div className="Title71">
                        Requesting A Payment
                    </div>
                    <div className="paragraph">
                        Creating payment requests is at the core of the BitKiosk platform. Seamlessly integrate your checkout experience with little code. 
                    </div>
                    <div className="paragraph">
                        This is where explanation happens with a resource or something else 
                    </div>
                </div>
                <div className="right">
                        <div className="http-status-code">
                            <div className="status-title">
                                HTTP STATUS CODE SUMMARY
                            </div>
                            <div className="table-wrap">
                                {requestPaymentStatusElements}
                            </div>
                        </div>
                        <div className="break">

                        </div>
                        <div className="Title72" style={{width:"400px"}}>
                            PARAMATERS
                        </div>
                        <div className="paragraph" style={{width:"400px"}}>
                            Headers - sent with every request
                        </div>
                        <div className="header-wrap">
                            <div className="row">
                                <div className="toptext">
                                Content-Type: application/json
                                </div>
                                <div className="subtext">
                                    Defining the type of content for the backend server to receive
                                </div>
                            </div>
                            <div className="row">
                                <div className="toptext">
                                x-bitkiosk-public-key: 
                                </div>
                                <span id="public-header-key-span">L6szix6HpiO75JOmQCnBxIeXEel9kH</span>
                                <div className="subtext">
                                    Public Key provided for all header requests. Provided by BitKiosk. Note this code is different from your public key.
                                </div>
                            </div>
                        </div>
                        <div className="paragraph" style={{width:"400px"}}>
                            Data Paramaters - Required for Customizing Payments
                        </div>
                        <div className="data-param-wrap">
                            <div className="row">
                                    <div className="toptext">
                                    *payment_details
                                    </div>
                                    <div className="subtext">
                                        Contains details specific to this payment request
                                    </div>
                                    <div className="internal-rules">
                                        {PaymentDetailParams_Element}
                                    </div>
                                </div>
                        </div>
                        <div className="break"></div>
                        <code className="request-payment-codeblock1">
                            <pre>
                            {curlCommand_RequestPayment}
                            </pre>
                        </code>
                </div>
            </div>
        </div>
        </div>
    );
}

export default DocPage;