import React, { useReducer, useEffect, useState } from 'react';

import Text from '../UIElements/Text';
import Title from '../UIElements/Title';

import { validate } from '../../util/validators';
import './NewInput.css';

const inputReducer = (state, action) => {
    switch (action.type) {
      case 'CHANGE':
        return {
          ...state,
          value: action.val,
          isValid: validate(action.val, action.validators)
        };
      case 'TOUCH': {
        return {
          ...state,
          isTouched: true
        }
      }
      default:
        return state;
    }
  };



  const NewInput = props => {
    const [inputState, dispatch] = useReducer(inputReducer, {
      value: props.initialValue || '',
      isTouched: false,
      isValid: props.initialValid || false
    });

    const [labelFocused,setLabelFocused] = useState(false);
  
    const { id, onInput, mismatch, mismatchText, readonly, ref, formAttempt } = props;
    const { value, isValid } = inputState;
  
    useEffect(() => {
      onInput(id, value, isValid)
    }, [id, value, isValid, onInput]);
  
    const changeHandler = event => {
      dispatch({
        type: 'CHANGE',
        val: event.target.value,
        validators: props.validators
      });
    };
  
    const touchHandler = () => {
        console.log(value);
        if(value === ''){
            setLabelFocused(false);
        }
      dispatch({
        type: 'TOUCH'
      });
    };

    const focusHandler = () => {
        setLabelFocused(true);
      };
  
    useEffect(() => {
      if(props.initialValue){
        dispatch({
          type: 'CHANGE',
          val: props.initialValue,
          validators: props.validators
        });
      }
    }, [props.initialValue]);
  
    const element =
      props.element === 'input' ? (
        <input
          id={props.id}
          type={props.type}
          placeholder={props.placeholder}
          onChange={changeHandler}
          onFocus={focusHandler}
          onBlur={touchHandler}
          value={inputState.value}
          readOnly={readonly}
          ref={ref}
        />
      ) : (
        <textarea
          id={props.id}
          rows={props.rows || 3}
          onChange={changeHandler}
          onBlur={touchHandler}
          value={inputState.value}
          readOnly={readonly}
          ref={ref}
        />
      );
  
    return (
      <div
        className={`input-control ${!inputState.isValid && formAttempt &&
          'input-control--invalid'}` 
        }
      >
        <label className={`${labelFocused ? "input-label-focused" : 'input-label'}`} htmlFor={props.id}>{props.label}</label>
        {element}
        {!inputState.isValid && formAttempt ? <p>{props.errorText}</p> : <p></p>}
        {mismatch && <p>{mismatchText}</p>}
      </div>
    );
  };
  
  export default NewInput;